import { Form, message } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DB, ROLES, ROUTES } from '../../common/constants';
import { generateRandomPassword, generateUniqueId } from '../../common/utils';
import { messageContext } from '../../components/AppContextHolder';
import { createDataWithFile } from '../../firebase/collections/utils';
import useAuthState from '../../hooks/useAuthState';
import StudentForm from './Components/StudentForm';
import './users.scss';

function AddStudent() {
  const [form] = Form?.useForm();
  const [fileList, setFileList] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { user } = useAuthState();
  const navigate = useNavigate();
  const [password /* setPassword */] = useState(generateRandomPassword(12));

  const onFinish = async (values) => {
    setButtonLoading(true);
    const { profilePhoto, ...restValues } = values;
    const newFormValues = {
      ...restValues,
      role: ROLES.STUDENT.key,
      address: restValues?.address || '',
      fatherName: restValues?.fatherName || '',
      motherName: restValues?.motherName || '',
      editedBy: user?.id,
      createdBy: user?.id,
      loginId: generateUniqueId('GC_'),
    };
    try {
      const success = await createDataWithFile(
        DB.STUDENTS,
        newFormValues,
        fileList?.length > 0 ? fileList?.[0]?.originFileObj : null,
        true,
      );

      if (success) {
        messageContext.success('Student added successfully!');
        form.resetFields();
        setFileList([]);
        setButtonLoading(false);
        navigate(ROUTES.VIEW_STUDENTS);
      } else {
        message?.error('Failed to add student. Please try again.');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error adding student:', error.message);
      message?.error('Failed to add student. Please try again.');
    }
  };

  const initialValues = {
    password,
  };

  return (
    <>
      <div className="mb-16 d-flex justify-between align-center">
        <h4 className="text-header">Add student</h4>
      </div>
      <div className="add-studen">
        <StudentForm
          form={form}
          onFinish={onFinish}
          setFileList={setFileList}
          fileList={fileList}
          buttonLoading={buttonLoading}
          initialValues={initialValues}
        />
      </div>
    </>
  );
}

export default AddStudent;
