import { DownOutlined, IdcardOutlined, LoginOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Space } from 'antd';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import logoimg from '../../../assets/images/logo.jpeg';
import { ROLES, ROUTES } from '../../../common/constants';
import useUserPermission from '../../../hooks/usePermission';

const UserProfile = () => {
  const {
    authUser: { displayName, photoURL },
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const { userRole } = useUserPermission();
  const items = [
    (userRole === ROLES.ADMIN.key || userRole === ROLES.SUPER_ADMIN.key) && {
      key: 'profile',
      label: <Link to={ROUTES?.PROFILE}>My Profile</Link>,
      icon: <IdcardOutlined />,
    },
    {
      key: 'logout',
      label: <Link to={ROUTES?.LOGOUT}>Logout</Link>,
      icon: <LoginOutlined />,
    },
  ];

  return (
    <Dropdown
      menu={{ items, selectedKeys: [`${pathname?.split('/')?.[1]}`] }}
      trigger={['click']}
    >
      <Space className="pointer d-flex align-center gap-4" size="small">
        {photoURL ? (
          <Avatar alt="Avatar" src={logoimg} />
        ) : (
          <Avatar alt="Avatar">
            {displayName?.split(' ')?.[0]?.charAt(0)?.toUpperCase() || null}
            {displayName?.split(' ')?.[1]?.charAt(0)?.toUpperCase() || null}
          </Avatar>
        )}
        <span className="m-hide">{displayName}</span>
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default UserProfile;
