/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  PROFILE: '/profile',
  NOT_FOUND: '/404',
  VIEW_STUDENTS: '/students',
  ADD_STUDENT: '/students/add',
  EDIT_STUDENT: '/students/edit',
  VIEW_EXAMS: '/exams',
  ADD_EXAM: '/exams/add',
  EDIT_EXAM: '/exams/edit',
  ADD_NOTIFICATION: '/add-notification',
  NOTIFICATIONS: '/notifications',
  EDIT_NOTIFICATION: '/notification/edit',
  ATTENDANCE_MANAGEMENT: '/attendance-menagement',
  TEAM: '/team',
  ADD_TEAM: '/team/add',
  EDIT_TEAM: '/team/edit',
  COMPANY: '/company',
  ADD_COMPANY: '/add-company',
  EDIT_COMPANY: '/edit-company',
  MARKSHEET: '/marksheet',
  GALLARY: '/gallery',
  SUBJECTS: '/subjects',
  SCHOOLS: '/schools',
  PROGRESS: '/progress',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'My Dashboard',
  STUDENTS: 'Students',
  EXAMS: 'Exams',
  ADD_STUDENT: 'Add Student',
  VIEW_STUDENTS: 'View Students',
  ADD_EXAM: 'Add Exam',
  VIEW_EXAMS: 'View Exams',
  NOTIFICATIONS: 'Notifications',
  ADD_NOTIFICATION: 'Add Notification',
  ATTENDANCE_MANAGEMENT: 'Attendance',
  TEAM: 'Our Team',
  ADD_TEAM: 'Add Team',
  VIEW_TEAM: 'View Team',
  EDIT_TEAM: 'Edit Team',
  COMPANY: 'About us',
  ADD_COMPANY: 'Add Company',
  MARKSHEET: 'Marksheet',
  GALLARY: 'Gallery',
  SUBJECTS: 'Subjects',
  SCHOOLS: 'Schools',
  PROGRESS: 'Progress',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const ROLES = {
  SUPER_ADMIN: { key: 'SUPER_ADMIN', label: 'Super admin' },
  ADMIN: { key: 'ADMIN', label: 'Admin' },
  STUDENT: { key: 'STUDENT', label: 'Student' },
  FACULTY: { key: 'FACULTY', label: 'Faculty member' },
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  STUDENT: 'STUDENT',
  FACULTY: 'FACULTY',
};

/* Date and time */
export const defaultDateFormat = 'DD/MM/YYYY';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
};

export const LIMIT = 10;
export const SCROLL_PERCENT = 0.85;

export const MOVIES_SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'Updated At', value: 'updatedAt' },
  { name: 'Release Date', value: 'releaseDate' },
  { name: 'Popularity', value: 'popularity' },
  { name: 'Rate', value: 'voteAverage' },
];
export const ORDER = [
  { name: 'Ascending', value: 'ASC' },
  { name: 'Descending', value: 'DESC' },
];

export const CREDIT_TYPE = [
  { name: 'Cast', value: 'CAST' },
  { name: 'Crew', value: 'CREW' },
];

export const GENDER = [
  { name: 'Female', value: 'FEMALE' },
  { name: 'Male', value: 'MALE' },
  { name: 'Other', value: 'OTHER' },
];
export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const MOVIE_STATUS = {
  PENDING: 'Pending',
  RELEASED: 'Released',
};

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const DB = {
  USERS: 'users',
  MEMBER: 'member',
  STUDENTS: 'students',
  SCHOOLS: 'schools',
  NOTIFICATION: 'notification',
  EXAMS: 'exams',
  TEAMS: 'teams',
  COMPANY: 'company',
  ATTENDANCE: 'attendance',
  MARKSHEET: 'marksheet',
  MARKS: 'marks',
  GALLERY: 'gallery',
  SUBJECTS: 'subjects',
  PROGRESS: 'progeress',
};

export const STORAGE = {
  PROFILE: 'profile',
};

export const DEFAULT_PAGINATION = {
  LIMIT: 5,
};

export const CLASS_LIST = {
  STD_5_ENG: { key: 'STD_5_ENG', label: 'Std 5 (Eng)' },
  STD_5_GUJ: { key: 'STD_5_GUJ', label: 'Std 5 (Guj)' },
  STD_6_GUJ: { key: 'STD_6_GUJ', label: 'Std 6 (Guj)' },
  STD_6_ENG: { key: 'STD_6_ENG', label: 'Std 6 (Eng)' },
  STD_7_GUJ: { key: 'STD_7_GUJ', label: 'Std 7 (Guj)' },
  STD_7_ENG: { key: 'STD_7_ENG', label: 'Std 7 (Eng)' },
  STD_8_GUJ: { key: 'STD_8_GUJ', label: 'Std 8 (Guj)' },
  STD_8_ENG: { key: 'STD_8_ENG', label: 'Std 8 (Eng)' },
  STD_9_GUJ: { key: 'STD_9_GUJ', label: 'Std 9 (Guj)' },
  STD_9_ENG: { key: 'STD_9_ENG', label: 'Std 9 (Eng)' },
  STD_10_A_GUJ: { key: 'STD_10_A_GUJ', label: 'Std 10 A (Guj)' },
  STD_10_A_ENG: { key: 'STD_10_A_ENG', label: 'Std 10 A (Eng)' },
  STD_10_B_GUJ: { key: 'STD_10_B_GUJ', label: 'Std 10 B (Guj)' },
  STD_10_B_ENG: { key: 'STD_10_B_ENG', label: 'Std 10 B (Eng)' },
  STD_11_GUJ_SCIENCE: {
    key: 'STD_11_GUJ_SCIENCE',
    label: 'Std 11 (Guj-Science)',
  },
  STD_11_ENG_SCIENCE: {
    key: 'STD_11_ENG_SCIENCE',
    label: 'Std 11 (Eng-Science)',
  },
  STD_11_GUJ_COMMERCE: {
    key: 'STD_11_GUJ_COMMERCE',
    label: 'Std 11 (Guj-Commerce)',
  },
  STD_11_ENG_COMMERCE: {
    key: 'STD_11_ENG_COMMERCE',
    label: 'Std 11 (Eng-Commerce)',
  },
  STD_12_GUJ_SCIENCE: {
    key: 'STD_12_GUJ_SCIENCE',
    label: 'Std 12 (Guj-Science)',
  },
  STD_12_ENG_SCIENCE: {
    key: 'STD_12_ENG_SCIENCE',
    label: 'Std 12 (Eng-Science)',
  },
  STD_12_GUJ_COMMERCE: {
    key: 'STD_12_GUJ_COMMERCE',
    label: 'Std 12 (Guj-Commerce)',
  },
  STD_12_ENG_COMMERCE: {
    key: 'STD_12_ENG_COMMERCE',
    label: 'Std 12 (Eng-Commerce)',
  },
};

export const SUBJECTS = {
  MATH: { key: 'MATH', label: 'Math' },
  ENGLISH: { key: 'ENGLISH', label: 'English' },
};

export const COMPANY = {
  NAME: 'Genius Classes',
};
