import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import { DB, ROLES, ROLE_KEYS } from '../common/constants';
import { getLoggedUserDetails } from '../firebase/collections/users';

const useUserPermission = () => {
  const { authUser } = useContext(AppContext);
  const [userRole, setUserRole] = useState(null);
  const [permissionLoading, setPermissionLoading] = useState(true); // Added loading state

  useEffect(() => {
    const collection =
      authUser?.role === ROLES.STUDENT.key ? DB.STUDENTS : DB.TEAMS;
    const id =
      authUser?.role === ROLES.STUDENT.key ? authUser?.doc_id : authUser?.id;
    const fetchUserRole = async () => {
      try {
        if (!authUser) {
          setPermissionLoading(false);
          return;
        }
        const data = await getLoggedUserDetails(
          authUser?.accessToken?.length > 0 ? DB.USERS : collection,
          authUser?.accessToken?.length > 0 ? authUser?.uid : id,
        );
        if (data) {
          setUserRole(data?.role);
          setPermissionLoading(false);
        }
      } catch (error) {
        setPermissionLoading(false);
        // eslint-disable-next-line no-console
        console.error('Error fetching user role:', error);
      }
    };

    fetchUserRole();
  }, [authUser]);

  // Define permission check functions
  const isAdmin = () =>
    userRole === ROLE_KEYS.ADMIN || userRole === ROLE_KEYS.SUPER_ADMIN;
  const isStudent = () => userRole === ROLES.STUDENT.key;
  const isFaculty = () => userRole === ROLES.FACULTY.key;

  return {
    userRole,
    isAdmin,
    isStudent,
    isFaculty,
    permissionLoading,
  };
};

export default useUserPermission;
