import { Button, Col, DatePicker, Form, Input, Row, Select, Space } from 'antd';
import Card from 'antd/es/card/Card';
import dayjs from 'dayjs';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { CLASS_LIST, DB, ROUTES } from '../../../common/constants';
import { formValidatorRules, sortDataList } from '../../../common/utils';
import { getAllDocuments } from '../../../firebase/collections/utils';
import useRouter from '../../../hooks/useRouter';

const { required } = formValidatorRules;
const { Option } = Select;

function ExamForm({ form, initialValues, onFinish, buttonLoading = false }) {
  const { navigate } = useRouter();
  const disabledDate = (current) => current && current < dayjs().startOf('day');
  const [subjects, setSubjects] = useState([]);
  const fetchNotificationData = async (field = null, value = null) => {
    let data;
    if (field && value) {
      data = await getAllDocuments(DB.SUBJECTS, field, value);
    } else {
      data = await getAllDocuments(DB.SUBJECTS);
    }
    setSubjects(sortDataList(data));
  };

  useEffect(() => {
    fetchNotificationData();
  }, []);

  return (
    <Form
      form={form}
      className="sticky-action-form data-form full-height"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Card
        className="ant-body-scroll"
        actions={[
          <div key="actionbutton" className="text-right">
            <Space>
              <Button
                size="large"
                onClick={() => navigate(ROUTES.VIEW_EXAMS)}
                disabled={buttonLoading}
              >
                Cancel
              </Button>
              <Button
                size="large"
                type="primary"
                loading={buttonLoading}
                htmlType="submit"
              >
                Save
              </Button>
            </Space>
          </div>,
        ]}
      >
        <div className="card-body-wrapper">
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={8} xl={8}>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    ...required,
                    message: 'Please enter title!',
                    whitespace: true,
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Title" />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8} xl={8}>
              <Form.Item
                name="examDate"
                label="Date"
                rules={[{ ...required, message: 'Please select exam date!' }]}
              >
                <DatePicker
                  className="w-full"
                  size="large"
                  placeholder="Select Date"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8} xl={8}>
              <Form.Item
                name="examClass"
                label="Class"
                rules={[
                  {
                    ...required,
                    message: 'Please select exam class!',
                  },
                ]}
              >
                <Select size="large" placeholder="Select Class" showSearch>
                  {map(CLASS_LIST, (item) => (
                    <Option key={item?.key}>{item.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8} xl={8}>
              <Form.Item
                name="examSubject"
                label="Subject"
                rules={[
                  {
                    ...required,
                    message: 'Please Select exam subject!',
                  },
                ]}
              >
                <Select size="large" placeholder="Select Subject" showSearch>
                  {map(subjects, (item) => (
                    <Option key={item?.id}>{item.title}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} lg={8} xl={8}>
              <Form.Item name="totalMarks" label="Total Marks">
                <Input type="number" size="large" placeholder="Enter Marks" />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Card>
    </Form>
  );
}

export default ExamForm;
