import { CloseOutlined, PictureFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Empty, Form, Modal, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { DB } from '../../common/constants';
import {
  beforeUpload,
  beforeUploadImage,
  deleteByDocumentId,
  sortDataList,
} from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import {
  getAllDocuments,
  uploadImageToFirestore,
} from '../../firebase/collections/utils';

function GalleryPage() {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async (field = null, value = null) => {
    let newData;
    if (field && value) {
      newData = await getAllDocuments(DB.GALLERY, field, value);
    } else {
      newData = await getAllDocuments(DB.GALLERY);
    }
    setData(sortDataList(newData));
    setLoading(false);
  };

  const removeImage = async (imgId) => {
    setLoading(true);
    try {
      const res = await deleteByDocumentId(DB.GALLERY, imgId);
      if (res) {
        fetchData();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="mt-8">Click here to select image</div>
    </div>
  );
  const handleChange = async ({ fileList: newFileList, file }) => {
    if (file?.status === 'removed') {
      setFileList([]);
      return;
    }
    if (beforeUploadImage(file, 3)) {
      setFileList(newFileList);
    }
  };
  const onFinish = async (values) => {
    setUploading(true);
    const { image } = values || {};
    try {
      const res = await uploadImageToFirestore(image?.file, DB.GALLERY);
      if (res) {
        setOpen(false);
        setUploading(false);
        form.resetFields();
        setFileList([]);
        fetchData();
      }
    } catch (error) {
      setUploading(false);
    }
  };

  return (
    <>
      <div className="mb-16 d-flex justify-between align-center">
        <h4 className="text-header">Gallary</h4>
        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setOpen(true)}
        >
          Add Image
        </Button>
      </div>
      {open && (
        <Modal
          open={open}
          onCancel={() => {
            setOpen(false);
            form.resetFields();
            setFileList([]);
          }}
          onOk={form?.submit}
          width={348}
          okButtonProps={{ size: 'large', loading: uploading }}
          cancelButtonProps={{ size: 'large', disabled: uploading }}
          okText="Upload"
        >
          <Form
            form={form}
            className="sticky-action-form data-form"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item name="image" label="Image">
              <Upload
                name="avatar"
                listType="picture-card"
                className="gallary-uploader"
                showUploadList={{
                  previewIcon: true,
                  showRemoveIcon: true,
                }}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                fileList={fileList}
              >
                {fileList?.length > 0 ? null : uploadButton}
              </Upload>
            </Form.Item>
          </Form>
        </Modal>
      )}

      <div className="page-bg p-16">
        <LoaderComponent spinning={loading}>
          {data?.length === 0 ? (
            <div className="d-flex flex-vertical justify-center align-center gap-10">
              <Empty description="No image found" />
              <Button
                size="large"
                type="primary"
                icon={<PictureFilled />}
                onClick={() => setOpen(true)}
              >
                Upload
              </Button>
            </div>
          ) : (
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry gutter="12px" columnsCount={2}>
                {data?.map((image) => (
                  <div key={image?.id} className="gallery-img">
                    <span
                      className="delete-img"
                      onClick={() => removeImage(image?.id)}
                    >
                      <CloseOutlined />
                    </span>
                    <img
                      className="pointer"
                      src={image.url}
                      alt="Mount Bromo, Indonesia"
                    />
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          )}
        </LoaderComponent>
      </div>
    </>
  );
}

export default GalleryPage;
