import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Space, Upload } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { beforeUpload, beforeUploadImage } from '../../../common/utils';

function NotificationForm({
  form,
  onFinish,
  loading = false,
  initialValues = null,
  setFileList,
  fileList = [],
}) {
  const navigate = useNavigate();
  const handleChange = async ({ fileList: newFileList, file }) => {
    if (file?.status === 'removed') {
      setFileList([]);
      return;
    }
    if (beforeUploadImage(file, 3)) {
      setFileList(newFileList);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="mt-8">Upload</div>
    </div>
  );

  return (
    <Form
      form={form}
      className="sticky-action-form data-form"
      layout="vertical"
      onFinish={onFinish}
      initialValues={initialValues}
    >
      <Card
        className="ant-body-scroll"
        actions={[
          <div key="actionbutton" className="text-right">
            <Space>
              <Button
                size="large"
                onClick={() => navigate(ROUTES.NOTIFICATIONS)}
              >
                Cancel
              </Button>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Save
              </Button>
            </Space>
          </div>,
        ]}
      >
        <div className="card-body-wrapper">
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={24} xl={24}>
              <Form.Item name="image" label="Image">
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={{
                    previewIcon: true,
                    showRemoveIcon: true,
                  }}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  fileList={fileList}
                >
                  {fileList?.length > 0 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} xl={12}>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: 'Please enter notification title!',
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Title" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} lg={12} xl={12}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: 'Please enter notification description!',
                  },
                ]}
              >
                <Input.TextArea
                  rows={5}
                  size="large"
                  placeholder="Enter Description"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Card>
    </Form>
  );
}

export default NotificationForm;
