import { UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Row,
  Space,
} from 'antd';
import dayjs from 'dayjs';
import { map } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { DB, ROLE_KEYS, ROUTES } from '../../../common/constants';
import {
  formValidatorRules,
  returnCurrentSubject,
  sortDataList,
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import { getAllDocuments } from '../../../firebase/collections/utils';
import useUserPermission from '../../../hooks/usePermission';

const { required } = formValidatorRules;

function CompanyForm({
  submitLoading = false,
  form,
  title,
  onFinish,
  initialValues = null,
  onValueChanges,
  isValueChange = false,
}) {
  const { subjects, authUser } = useContext(AppContext);
  const [teams, setTeams] = useState([]);
  const { isAdmin, isStudent } = useUserPermission();
  const [gallery, setGallery] = useState([]);
  const [loadingImages, setLoadingImages] = useState(true);
  const isNotAdmin = useMemo(() => {
    if (
      authUser?.role === ROLE_KEYS.STUDENT ||
      authUser?.role === ROLE_KEYS.FACULTY
    ) {
      return true;
    }
    return false;
  }, [authUser]);
  const fetchTeam = async (field = null, value = null) => {
    let data;
    if (field && value) {
      data = await getAllDocuments(DB.TEAMS, field, value);
    } else {
      data = await getAllDocuments(DB.TEAMS);
    }
    setTeams(sortDataList(data));
    setLoadingImages(false);
  };

  const fetchGallery = async (field = null, value = null) => {
    let newData;
    if (field && value) {
      newData = await getAllDocuments(DB.GALLERY, field, value);
    } else {
      newData = await getAllDocuments(DB.GALLERY);
    }
    setGallery(sortDataList(newData));
  };

  useEffect(() => {
    fetchTeam(isNotAdmin ? 'isVisible' : null, isNotAdmin ? true : null);
    fetchGallery();
  }, [isNotAdmin]);

  const navigate = useNavigate();
  const PhoneNumberRegex = /^\d{10,}$/;
  const validatePhoneNumber = (_, value) => {
    if (!value || PhoneNumberRegex.test(value)) {
      return Promise.resolve();
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('Please enter a valid phone!');
  };

  return (
    <Form
      form={form}
      className="sticky-action-form data-form"
      layout="vertical"
      initialValues={{
        ...initialValues,
        startYear: dayjs(initialValues?.startYear.toDate()),
      }}
      onFinish={onFinish}
      onValuesChange={onValueChanges}
      disabled={!isAdmin()}
    >
      <Card
        className="ant-body-scroll"
        title={title}
        actions={
          isAdmin() && [
            <div key="actionbutton" className="text-right">
              <Space>
                <Button
                  size="large"
                  disabled={submitLoading}
                  onClick={() => navigate(ROUTES.TEAM)}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  type="primary"
                  loading={submitLoading}
                  disabled={!isValueChange}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Space>
            </div>,
          ]
        }
      >
        <div className="card-body-wrapper">
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={8} xl={8}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ ...required, message: 'Please enter name!' }]}
              >
                <Input size="large" placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8} xl={8}>
              <Form.Item
                name="description"
                label="Description"
                rules={[{ ...required, message: 'Please enter description!' }]}
              >
                <Input size="large" placeholder="Enter description" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8} xl={8}>
              <Form.Item
                name="startYear"
                label="Start Year"
                rules={[{ ...required, message: 'Please enter start year!' }]}
              >
                <DatePicker size="large" className="w-full" />
              </Form.Item>
            </Col>
            {isAdmin() && (
              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone"
                  rules={[
                    {
                      validator: validatePhoneNumber,
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter Phone" />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} lg={8} xl={8}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { ...required, message: 'Please enter email!' },
                  {
                    type: 'email',
                    message: 'Please enter a valid email!',
                  },
                ]}
              >
                <Input type="email" size="large" placeholder="Enter Email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={8} xl={8}>
              <Form.Item name="address1" label="Address (Primary)">
                <Input.TextArea
                  rows={3}
                  size="large"
                  placeholder="Enter address"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8} xl={8}>
              <Form.Item name="address2" label="Address (Secondary)">
                <Input.TextArea
                  rows={3}
                  size="large"
                  placeholder="Enter address"
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="mb-16 d-flex justify-between align-center">
            <h6>Our Team</h6>
          </div>
          <Row gutter={[16, 16]}>
            {map(teams, (team) => (
              <Col xs={24} md={12} lg={8} xl={8}>
                <Badge.Ribbon text={team?.designation} color="var(--blue2)">
                  <Card hoverable>
                    <div className="d-flex gap-8 flex-vertical">
                      <div className="d-flex gap-8 mt-16 justify-center">
                        {team?.profilePhoto ? (
                          <Image
                            src={team?.profilePhoto}
                            className="rounded-img"
                          />
                        ) : (
                          <Avatar
                            icon={<UserOutlined />}
                            size="large"
                            className="rounded-img"
                          />
                        )}
                      </div>
                      <div className="d-flex gap-8 mt-16">
                        Name: <span>{team?.name}</span>
                      </div>
                      <div className="d-flex gap-8">
                        Subject:{' '}
                        <span>
                          {team?.subject
                            ?.map(
                              (item) =>
                                returnCurrentSubject(item, subjects)?.title ||
                                '-',
                            )
                            ?.join(', ')}
                        </span>
                      </div>

                      {!isStudent() && (
                        <>
                          <div className="d-flex gap-8">
                            Phone: <span>{team?.phoneNumber}</span>
                          </div>
                          <div className="d-flex gap-8">
                            Email: <span>{team?.email}</span>
                          </div>
                        </>
                      )}

                      <div className="d-flex gap-8">
                        Address: <span>{team?.address}</span>
                      </div>
                    </div>
                  </Card>
                </Badge.Ribbon>
              </Col>
            ))}
          </Row>
          <div className="mb-16 mt-16 d-flex justify-between">
            <h6>Gallery</h6>
          </div>
          <div>
            <LoaderComponent spinning={loadingImages}>
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
              >
                <Masonry gutter="12px" columnsCount={2}>
                  {gallery?.map((image) => (
                    <div key={image?.id}>
                      <img
                        className="pointer"
                        src={image.url}
                        alt="Mount Bromo, Indonesia"
                      />
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </LoaderComponent>
          </div>
        </div>
      </Card>
    </Form>
  );
}

export default CompanyForm;
