import { Col, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { DB } from '../../../common/constants';
import { sortDataList } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import { db } from '../../../firebase';
import { getAllDocuments } from '../../../firebase/collections/utils';

const { Option } = Select;

function AttendanceBarChart() {
  const [selectedFaculty, setSelectedFaculty] = useState(null);
  const [attendanceData, setAttendanceData] = useState(null);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchTeam = async () => {
    try {
      const data = await getAllDocuments(DB.TEAMS);
      setTeams(sortDataList(data));
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    fetchTeam();
  }, []);

  const handleFacultySelect = async (value) => {
    setSelectedFaculty(value);
    setLoading(true);
    try {
      const attendanceRef = collection(db, 'faculty_attendance');
      const querySnapshot = await getDocs(attendanceRef);

      const monthlyAttendanceData = {};

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (value in data) {
          const attendanceDate = dayjs(doc.id); // Assuming document IDs are dates
          const monthYear = `${attendanceDate.format('MMM YYYY')}`;
          monthlyAttendanceData[monthYear] =
            monthlyAttendanceData[monthYear] || 0;
          monthlyAttendanceData[monthYear] += data[value] ? 1 : 0; // Increment attendance count for the month
        }

        setLoading(false);
      });

      // Convert monthlyAttendanceData object to arrays for ApexCharts
      const labels = Object.keys(monthlyAttendanceData);
      const series = Object.values(monthlyAttendanceData);

      setAttendanceData({ labels, series });

      if (querySnapshot.docs?.length === 0) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const options = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: attendanceData ? attendanceData.labels : [],
    },
    yaxis: {
      title: {
        text: 'Attendance Count',
      },
    },
    legend: {
      show: true,
    },
  };

  return (
    <div className="w-full">
      <Row>
        <Col span={8}>
          <Select
            className="w-full"
            placeholder="Select faculty"
            onChange={handleFacultySelect}
            value={selectedFaculty}
            size="large"
          >
            {teams.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      {attendanceData && (
        <LoaderComponent spinning={loading}>
          <ReactApexChart
            options={options}
            series={[
              { data: attendanceData.series, name: 'Total present count' },
            ]}
            type="bar"
            height={350}
          />
        </LoaderComponent>
      )}
    </div>
  );
}

export default AttendanceBarChart;
