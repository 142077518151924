import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DB, ROUTES } from '../../common/constants';
import { messageContext } from '../../components/AppContextHolder';
import LoaderComponent from '../../components/LoaderComponent';
import {
  getDataByDocumentId,
  updateDataWithFile,
} from '../../firebase/collections/utils';
import TeamForm from './components/TeamForm';

function EditTeam() {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [dataLoading, setDataLoading] = useState(true);
  const [team, setTeam] = useState(null);
  const [fileList, setFileList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [buttonLoading, setButtonLoading] = useState(false);

  const fetchData = async () => {
    const data = await getDataByDocumentId(DB.TEAMS, id);
    setTeam(data);
    setDataLoading(false);
    setFileList(
      data?.profilePhoto
        ? [{ url: data?.profilePhoto, title: 'profile photo' }]
        : [],
    );
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const onFinish = async (values) => {
    setSubmitLoading(true);
    const { profilePhoto, ...restValues } = values;
    const newFormValues = {
      ...restValues,
      education: restValues?.education || '',
      experience: restValues?.experience || '',
      email: restValues?.email || '',
      phoneNumber: restValues?.phoneNumber || '',
    };
    const res = await updateDataWithFile(
      DB.TEAMS,
      id,
      newFormValues,
      fileList?.length > 0 ? fileList?.[0]?.originFileObj : null,
    );
    if (res) {
      messageContext.success('Team updated!');
      setSubmitLoading(false);
      navigate(ROUTES.TEAM);
    } else {
      setSubmitLoading(false);
      messageContext.error('Try again. Team not updated');
    }
  };

  if (dataLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className="mb-16 d-flex justify-between align-center">
        <h4 className="text-header">Edit team</h4>
      </div>
      <div className="h-full">
        <TeamForm
          onFinish={onFinish}
          form={form}
          submitLoading={submitLoading}
          initialValues={team}
          fileList={fileList}
          setFileList={setFileList}
          buttonLoading={buttonLoading}
        />
      </div>
    </>
  );
}

export default EditTeam;
