// AttendanceManagementPage.jsx
import { Tabs } from 'antd';
import React from 'react';
import useUserPermission from '../../hooks/usePermission';
import AttendanceForm from './components/AttendanceForm';
import FacultyAttendance from './components/FacultyAttendance';

const AttendanceManagementPage = () => {
  const { isAdmin } = useUserPermission();

  const items = [
    {
      key: '1',
      label: <h6>Student</h6>,
      children: (
        <div className="p-16 page-bg">
          <AttendanceForm />
        </div>
      ),
    },
    isAdmin() && {
      key: '2',
      label: <h6>Faculty</h6>,
      children: (
        <div className="p-16 page-bg">
          <FacultyAttendance />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="mb-16 d-flex justify-between align-center">
        <h4 className="text-header">Attendance Management</h4>
      </div>
      <div className="">
        <Tabs
          className="attendence-tab"
          type="card"
          defaultActiveKey="1"
          items={items}
        />
      </div>
    </>
  );
};

export default AttendanceManagementPage;
