import { Form } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DB, ROUTES } from '../../common/constants';
import { messageContext } from '../../components/AppContextHolder';
import { createData } from '../../firebase/collections/utils';
import CompanyForm from './components/CompanyForm';

function AddCompany() {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setSubmitLoading(true);
    const { startYear, ...restValues } = values || {};
    const res = await createData(DB.COMPANY, {
      ...restValues,
      startYear: startYear?.toDate(),
    });
    if (res) {
      messageContext.success('Company added successfully!');
      setSubmitLoading(false);
      navigate(ROUTES.COMPANY);
    } else {
      setSubmitLoading(false);
      messageContext.error('Try again. Company not created');
    }
  };
  return (
    <CompanyForm
      form={form}
      submitLoading={submitLoading}
      onFinish={onFinish}
      title="Add company"
    />
  );
}

export default AddCompany;
