import { Form } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DB, ROUTES } from '../../common/constants';
import { messageContext } from '../../components/AppContextHolder';
import LoaderComponent from '../../components/LoaderComponent';
import {
  getDataByDocumentId,
  updateDocumentById,
} from '../../firebase/collections/utils';
import ExamForm from './components/ExamForm';

function EditExam() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [dataLoading, setDataLoading] = useState(true);
  const { id } = useParams();
  const [exam, setExam] = useState(null);
  const fetchData = async () => {
    const data = await getDataByDocumentId(DB.EXAMS, id);
    setExam(data);
    setDataLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    const { examDate, ...restValues } = values || {};
    const fireStoreExamDate = examDate.toDate();
    const res = await updateDocumentById(DB.EXAMS, id, {
      ...restValues,
      examDate: fireStoreExamDate,
    });
    if (res) {
      messageContext.success('Exam updated!');
      setLoading(false);
      navigate(ROUTES.VIEW_EXAMS);
    } else {
      setLoading(false);
      messageContext.error('Try again. Exam not updated');
    }
  };

  if (dataLoading) {
    return <LoaderComponent />;
  }
  const initialValues = {
    ...exam,
    examDate: dayjs(exam?.examDate?.toDate()),
  };
  return (
    <>
      <div className="mb-16 d-flex justify-between align-center">
        <h4 className="text-header">Edit exam</h4>
      </div>
      <div className="page-bg h-full">
        <ExamForm
          form={form}
          onFinish={onFinish}
          buttonLoading={loading}
          initialValues={initialValues}
        />
      </div>
    </>
  );
}

export default EditExam;
