import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { COMPANY, DB } from '../../common/constants';
import { messageContext } from '../../components/AppContextHolder';
import LoaderComponent from '../../components/LoaderComponent';
import {
  createData,
  getAllDocuments,
  updateDocumentById,
} from '../../firebase/collections/utils';
import CompanyForm from './components/CompanyForm';

function Company() {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [company, setCompany] = useState(null);
  const [isValueChange, setIsValueChange] = useState(false);

  const fetchData = async () => {
    const data = await getAllDocuments(DB.COMPANY);
    setCompany(data?.[0]);
    setDataLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onFinish = async (values) => {
    setSubmitLoading(true);
    let res;
    let status;
    if (company?.id?.length === 0 || !company?.id) {
      res = await createData(DB.COMPANY, {
        ...values,
        startYear: values?.startYear?.toDate(),
      });
      status = 'created';
    } else {
      res = await updateDocumentById(DB.COMPANY, company?.id, {
        ...values,
        startYear: values?.startYear?.toDate(),
      });
      status = 'updated';
    }
    if (res) {
      fetchData();
      messageContext.success(
        status === 'created' ? 'Details created!' : 'Details updated!',
      );
      setSubmitLoading(false);
      setIsValueChange(false);
    } else {
      setSubmitLoading(false);
      messageContext.error('Try again. Comapny not updated');
    }
  };

  const handleValueChanges = () => {
    setIsValueChange(true);
  };

  if (dataLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className="mb-16 d-flex justify-between align-center">
        <h4>{`About ${COMPANY.NAME}`}</h4>
      </div>
      <CompanyForm
        onFinish={onFinish}
        form={form}
        submitLoading={submitLoading}
        initialValues={company}
        onValueChanges={handleValueChanges}
        title=""
        isValueChange={isValueChange}
      />
    </>
  );
}

export default Company;
