import { ExportOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { saveAs } from 'file-saver';
import React from 'react';
import * as XLSX from 'xlsx';

const exportToExcel = (data, fileName) => {
  // Create a new workbook and add a new worksheet
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Generate Excel file
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  // Save the file
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}.xlsx`);
};

const ExcelExport = ({ data = [], fileName, title = 'Export' }) => {
  const handleExport = () => {
    exportToExcel(data, fileName);
  };

  return (
    <div>
      <Button
        disabled={data.length === 0}
        onClick={handleExport}
        icon={<ExportOutlined />}
        type="primary"
      >
        {title}
      </Button>
    </div>
  );
};

export default ExcelExport;
