import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { DB } from '../../../common/constants';
import { getAllDocuments } from '../../../firebase/collections/utils';
import { combineMarksWithExams } from '../../students/Components/StudentExamList';
import AttendancePieChart from './AttendancePieChart';
import ExamWiseMarks from './ExamWiseMarks';

function Statstics({ user, selectedSubject, selectedBatch }) {
  const [examAndMarks, setExamAndMarks] = useState({
    obtainedMarks: [],
    totalMarks: [],
    examTitles: [],
  });
  const [attendance, setAttendance] = useState(null);

  const fetchAttendance = async (batch) => {
    const res = await getAllDocuments(DB.ATTENDANCE, 'batch', batch);
    const filteredData = res?.filter((item) =>
      item.attendance.some((attendanceItem) => attendanceItem?.id === user?.id),
    );
    const convertDate = filteredData?.map((item) => ({
      ...item,
      date: item?.date?.toDate(),
    }));
    // Initialize an empty object to store counts for each month
    const attendanceCounts = {};

    // Loop through attendance data to count present and absent students for each month
    convertDate.forEach((entry) => {
      const date = new Date(entry.date);
      const month = date.toLocaleString('en-US', { month: 'long' }); // Get full month name
      const presentCount = entry.attendance.filter(
        (a) => a?.id === user?.id && a.present,
      ).length;
      const absentCount = entry.attendance.length - presentCount;
      if (!attendanceCounts[month]) {
        attendanceCounts[month] = { present: 0, absent: 0 };
      }

      attendanceCounts[month].present += presentCount;
      attendanceCounts[month].absent += absentCount;
    });

    // Generate array of full month names
    const fullMonthNames = Array.from({ length: 12 }, (_, i) => {
      const date = new Date(2000, i, 1);
      return date.toLocaleString('en-US', { month: 'long' });
    });

    const labels = fullMonthNames;
    const values = fullMonthNames.map((month) =>
      attendanceCounts[month] ? attendanceCounts[month].present : 0,
    );

    setAttendance({ labels, values });
  };

  const fetchData = async () => {
    const exams = await getAllDocuments(
      DB.EXAMS,
      'examClass',
      user?.currentClass,
    );
    const marks = await getAllDocuments(
      DB.MARKS,
      'studentId',
      `GC_${user?.doc_id}`,
    );
    const subjects = await getAllDocuments(DB.SUBJECTS);
    if (exams?.length > 0 && marks?.length > 0) {
      const data = combineMarksWithExams(exams, marks);
      const examData = data.map((item) => {
        const examSubject = subjects.find(
          (record) => record.id === item.examSubject,
        );
        const examTitle = item.title;
        const subject = examSubject.title;
        const examDate = new Date(item.examDate.seconds * 1000);
        const totalMarks = parseInt(item.totalMarks, 10);
        let obtainedMarks = 0;
        if (item.marks && item.marks.length > 0) {
          obtainedMarks = parseInt(item.marks[0].marks, 10);
        }
        return { examTitle, subject, examDate, totalMarks, obtainedMarks };
      });

      const examTitles = examData.map((item) =>
        item.examDate.toLocaleDateString(),
      );
      const tooltipTitle = examData.map(
        (item) => `${item.examTitle} - ${item.subject}`,
      );
      const examSubject = examData.map((item) => item.subject);
      const totalMarks = examData.map((item) => item.totalMarks);
      const obtainedMarks = examData.map((item) => item.obtainedMarks);

      setExamAndMarks({
        examTitles,
        totalMarks,
        obtainedMarks,
        tooltipTitle,
        examSubject,
      });
    }
  };

  useEffect(() => {
    fetchData();
    if (selectedBatch) {
      fetchAttendance(selectedBatch);
    }
  }, [selectedBatch, selectedSubject]);

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col md={24} lg={12} xl={12}>
          <ExamWiseMarks data={examAndMarks} />
        </Col>
        <Col md={24} lg={12} xl={12}>
          <AttendancePieChart attendanceData={attendance} />
        </Col>
      </Row>
    </div>
  );
}

export default Statstics;
