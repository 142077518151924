import { LockOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Spin } from 'antd';
import React from 'react';
import logoImage from '../../assets/images/logo.jpeg';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { resetPassword } from '../../firebase/collections/auth';
import useQueryString from '../../hooks/useQueryString';
import useRouter from '../../hooks/useRouter';

const { required } = formValidatorRules;

const ChangePassword = () => {
  const { navigate } = useRouter();
  const { oobCode } = useQueryString();
  const onFinish = async (formValues) => {
    const { password } = formValues;
    try {
      const response = await resetPassword(oobCode, password);
      if (response) {
        navigate(ROUTES?.LOGIN, { replace: true });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="login-wrap d-flex align-center justify-center">
      <Card className="full-width">
        <div className="text-center">
          <div className="login-logo-container">
            <img src={logoImage} alt="logo" />
          </div>
          <h2 className="text-center">Reset Password</h2>
          <p className="text-center">Enter a new password for your account</p>
        </div>
        <Spin spinning={false}>
          <Form
            name="reset-password"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            size="large"
          >
            <Form.Item
              name="password"
              rules={[{ required, message: 'Please enter password!' }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Enter password"
              />
            </Form.Item>
            <Form.Item
              name="retype-password"
              rules={[
                { required, message: 'Please enter confirm password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value !== getFieldValue('password')) {
                      return Promise?.reject(
                        new Error('Passwords do not match'),
                      );
                    }
                    return Promise?.resolve();
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Enter confirm password"
              />
            </Form.Item>
            <Form.Item className="full-width mb-8">
              <Button type="primary" className="full-width" htmlType="submit">
                Reset Password
              </Button>
            </Form.Item>
            <Form.Item className="text-center mb-0">
              <Button
                type="link"
                onClick={() => {
                  navigate(ROUTES?.LOGIN);
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </div>
  );
};

export default ChangePassword;
