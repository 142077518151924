import { Divider, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { DB } from '../../../common/constants';
import { returnCurrentSubject } from '../../../common/utils';
import { getAllDocuments } from '../../../firebase/collections/utils';

export function combineMarksWithExams(exams, marks) {
  const combinedExams = exams.map((exam) => {
    const matchingMarks = marks.filter((mark) => mark.examId === exam.id);
    return {
      ...exam,
      marks: matchingMarks,
    };
  });
  return combinedExams;
}

function StudentExamList({ exams = [] }) {
  const { subjects } = useContext(AppContext);
  const [examAndMarks, setExamAndMarks] = useState(exams);
  const { id } = useParams();
  const fetchData = async () => {
    const data = await getAllDocuments(DB.MARKS, 'studentId', `GC_${id}`);
    setExamAndMarks(combineMarksWithExams(exams, data));
  };

  useEffect(() => {
    fetchData();
  }, [exams, id]);

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Exam Date',
      dataIndex: 'examDate',
      key: 'examDate',
      render(date) {
        return dayjs(date?.toDate()).format('DD/MM/YYYY');
      },
    },
    {
      title: 'Given marks',
      dataIndex: 'givenMarks',
      key: 'givenMarks',
      render(_, record) {
        return record?.marks?.[0]?.marks;
      },
    },
    {
      title: 'Subject',
      dataIndex: 'examSubject',
      key: 'examSubject',
      render(subjectID) {
        return (
          <span>{returnCurrentSubject(subjectID, subjects)?.title || '-'}</span>
        );
      },
    },
    {
      title: 'Total marks',
      dataIndex: 'totalMarks',
      key: 'totalMarks',
    },
  ];

  return (
    <div>
      <Divider>
        <h6>Exam list</h6>
      </Divider>
      <Table columns={columns} dataSource={examAndMarks} pagination={false} />
    </div>
  );
}

export default StudentExamList;
