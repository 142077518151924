import React from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import Error404 from './Error404';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import { ROUTES } from './common/constants';
import LoaderComponent from './components/LoaderComponent';
import MaintenancePage from './components/MaintenancePage';
import useUserPermission from './hooks/usePermission';
import Dashboard from './modules/Dashboard/Dashboard';
import AttendanceManagementPage from './modules/attendance/AttendanceManagementPage';
import ForgetPassword from './modules/auth/ForgetPassword';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import ResetPassword from './modules/auth/ResetPassword';
import Signup from './modules/auth/Signup';
import AddCompany from './modules/company/AddCompany';
import Company from './modules/company/Company';
import EditCompany from './modules/company/EditCompany';
import AddExam from './modules/exams/AddExam';
import EditExam from './modules/exams/EditExam';
import ExamList from './modules/exams/ExamList';
import GalleryPage from './modules/gallary/GalleryPage';
import Marksheet from './modules/marksheet/Marksheet';
import AddNotification from './modules/notification/AddNotification';
import EditNotification from './modules/notification/EditNotification';
import ListNotifications from './modules/notification/ListNotifications';
import Profile from './modules/profile/Profile';
import SchoolList from './modules/school/SchoolList';
import AddStudent from './modules/students/AddStudent';
import EditStudent from './modules/students/EditStudent';
import StudentList from './modules/students/StudentList';
import SubjectList from './modules/subject/SubjectList';
import AddTeam from './modules/team/AddTeam';
import EditTeam from './modules/team/EditTeam';
import TeamList from './modules/team/TeamList';

const RoutesCollection = ({ isAdmin, isFaculty }) => {
  const AUTH_MODULES = [
    {
      path: ROUTES?.LOGIN,
      element: <PublicRoute />,
      // Nested routes use a children property
      children: [{ path: ROUTES?.LOGIN, element: <Login /> }],
    },
    {
      path: ROUTES?.FORGET_PASSWORD,
      element: <PublicRoute />,
      children: [
        { path: ROUTES?.FORGET_PASSWORD, element: <ForgetPassword /> },
      ],
    },
    {
      path: ROUTES?.SIGNUP,
      element: <PublicRoute />,
      children: [{ path: ROUTES?.SIGNUP, element: <Signup /> }],
    },
    {
      path: ROUTES?.RESET,
      element: <PublicRoute />,
      children: [{ path: ROUTES?.RESET, element: <ResetPassword /> }],
    },
    {
      path: ROUTES?.LOGOUT,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.LOGOUT, element: <Logout /> }],
    },
  ];

  const DASHBOARD_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [{ path: ROUTES?.MAIN, element: <Dashboard /> }],
        },
      ],
    },
  ];

  const PROFILE_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [{ path: ROUTES?.PROFILE, element: <Profile /> }],
        },
      ],
    },
  ];

  const STUDENT_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            { path: ROUTES?.VIEW_STUDENTS, element: <StudentList /> },
            { path: ROUTES?.ADD_STUDENT, element: <AddStudent /> },
            { path: `${ROUTES?.EDIT_STUDENT}/:id`, element: <EditStudent /> },
          ],
        },
      ],
    },
  ];

  const NOTIFICATION_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            { path: ROUTES?.ADD_NOTIFICATION, element: <AddNotification /> },
            { path: ROUTES?.NOTIFICATIONS, element: <ListNotifications /> },
            {
              path: `${ROUTES?.EDIT_NOTIFICATION}/:id`,
              element: <EditNotification />,
            },
          ],
        },
      ],
    },
  ];

  const EXAM_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            { path: ROUTES?.VIEW_EXAMS, element: <ExamList /> },
            { path: ROUTES?.ADD_EXAM, element: <AddExam /> },
            { path: `${ROUTES?.EDIT_EXAM}/:id`, element: <EditExam /> },
          ],
        },
      ],
    },
  ];

  const ATTENDANCE_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.ATTENDANCE_MANAGEMENT,
              element: <AttendanceManagementPage />,
            },
          ],
        },
      ],
    },
  ];

  const TEAM_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.TEAM,
              element: <TeamList />,
            },
            { path: `${ROUTES?.EDIT_TEAM}/:id`, element: <EditTeam /> },
            {
              path: ROUTES?.ADD_TEAM,
              element: <AddTeam />,
            },
          ],
        },
      ],
    },
  ];

  const COMPANY_MODULE = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.COMPANY,
              element: <Company />,
            },
            { path: `${ROUTES?.EDIT_COMPANY}/:id`, element: <EditCompany /> },
            {
              path: ROUTES?.ADD_COMPANY,
              element: <AddCompany />,
            },
          ],
        },
      ],
    },
  ];

  const MARKSHEET_MODULE = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.MARKSHEET,
              element: <Marksheet />,
            },
          ],
        },
      ],
    },
  ];
  const GALLERY_MODULE = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.GALLARY,
              element: <GalleryPage />,
            },
          ],
        },
      ],
    },
  ];

  const SUBJECTS_MODULE = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.SUBJECTS,
              element: <SubjectList />,
            },
          ],
        },
      ],
    },
  ];

  const SCHOOLS_MODULE = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.SCHOOLS,
              element: <SchoolList />,
            },
          ],
        },
      ],
    },
  ];

  const OTHER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: '*',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  let modules = [
    ...AUTH_MODULES,
    ...DASHBOARD_MODULES,
    ...TEAM_MODULES,
    ...COMPANY_MODULE,
    ...OTHER_MODULES,
  ];

  if (isAdmin()) {
    modules = modules.concat(
      STUDENT_MODULES,
      PROFILE_MODULES,
      NOTIFICATION_MODULES,
      EXAM_MODULES,
      GALLERY_MODULE,
      SUBJECTS_MODULE,
      SCHOOLS_MODULE,
    );
  }

  if (isAdmin() || isFaculty()) {
    modules = modules.concat(ATTENDANCE_MODULES, MARKSHEET_MODULE);
  }

  const element = useRoutes(modules);
  return element;
};

const RoutesWrapper = () => {
  const { isAdmin, isFaculty, permissionLoading } = useUserPermission();
  // const loading = false;

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (permissionLoading) return <LoaderComponent />;

  return (
    <Router>
      {maintenance === 'true' ? (
        <MaintenancePage />
      ) : (
        <RoutesCollection isAdmin={isAdmin} isFaculty={isFaculty} />
      )}
    </Router>
  );
};
export default RoutesWrapper;
