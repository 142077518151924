/* eslint-disable no-unused-vars */
import { Col, DatePicker, Form, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { map, orderBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { CLASS_LIST, DB, defaultDateFormat } from '../../../common/constants';
import { sortDataList } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import { getAllDocuments } from '../../../firebase/collections/utils';
import useUserPermission from '../../../hooks/usePermission';
import StudentAttendanceTable from './StudentAttendanceTable';

const { Option } = Select;

const AttendanceForm = () => {
  const [form] = Form.useForm();
  const { isFaculty, isAdmin, permissionLoading } = useUserPermission();
  const { authUser, subjects: allSubjects } = useContext(AppContext);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [subjects, setSubjects] = useState(sortDataList(allSubjects));
  const [classes, setClasses] = useState(CLASS_LIST);
  const [batch, setBatch] = useState('MORNING');
  const fetchData = async () => {
    if (isAdmin() && !permissionLoading) {
      const data = await getAllDocuments(DB.SUBJECTS);
      setSubjects(data);
    }
    if (isFaculty() && !permissionLoading) {
      setClasses(authUser?.class?.map((c) => CLASS_LIST?.[c]));
      const assignedSubjects = allSubjects?.filter((subject) =>
        authUser?.subject?.includes(subject.id),
      );
      setSubjects(assignedSubjects);
    }
  };

  useEffect(() => {
    fetchData();
  }, [permissionLoading]);

  const fetchStudentsData = async (field = null, value = null) => {
    let data;
    if (field && value) {
      data = await getAllDocuments(DB.STUDENTS, field, value);
    } else {
      data = await getAllDocuments(DB.STUDENTS);
    }

    setStudents(orderBy(data, 'name'));
  };

  useEffect(() => {
    if (selectedClass) {
      fetchStudentsData('currentClass', selectedClass);
    }
  }, [selectedClass]);
  const handleClassChange = async (classId) => {
    setSelectedClass(classId);
  };

  const handleSubjectChange = async (subjectId) => {
    setSelectedSubject(subjectId);
  };

  const handleDateChange = (date) => {
    if (date) {
      const jsDate = dayjs(date).toDate();
      jsDate.setHours(0, 0, 0, 0);
      setSelectedDate(jsDate);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const jsDate = dayjs().toDate();
      jsDate.setHours(0, 0, 0, 0);
      setSelectedDate(jsDate);
    }, 1000);
  }, []);

  useEffect(() => {
    form.setFieldValue('batch', batch);
  }, [batch]);

  const disabledDate = (current) => current && current > dayjs().endOf('day');
  if (permissionLoading) {
    return <LoaderComponent setHeight={50} />;
  }
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        batch,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} md={24} lg={8} xl={8}>
          <Form.Item
            name="classId"
            label="Class"
            rules={[{ required: true, message: 'Please select a class!' }]}
          >
            <Select
              onChange={handleClassChange}
              size="large"
              placeholder="Select Class"
              showSearch
              className="w-full"
            >
              {map(classes, (cls) => (
                <Option key={cls.key} value={cls.key}>
                  {cls.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col xs={24} md={24} lg={8} xl={8}>
          <Form.Item name="examSubject" label="Subject">
            <Select
              size="large"
              placeholder="Select Subject"
              showSearch
              onChange={handleSubjectChange}
            >
              {map(subjects, (item) => (
                <Option key={item?.id}>{item.title}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col> */}
        <Col xs={24} md={24} lg={8} xl={8}>
          <Form.Item
            name="date"
            label="Date"
            rules={[{ required: true, message: 'Please select a date!' }]}
          >
            <DatePicker
              size="large"
              className="w-full"
              defaultValue={dayjs()}
              onChange={handleDateChange}
              disabledDate={disabledDate}
              format={defaultDateFormat}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={24} lg={8} xl={8}>
          <Form.Item
            name="batch"
            label="Batch"
            rules={[{ required: true, message: 'Please select a date!' }]}
          >
            <Select
              onChange={setBatch}
              value={batch}
              size="large"
              placeholder="Please select batch"
            >
              <Select.Option key="MORNING">Morning</Select.Option>
              <Select.Option key="EVENING">Evening</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <StudentAttendanceTable
            students={students}
            selectedClass={selectedClass}
            selectedDate={selectedDate}
            // selectedSubject={selectedSubject}
            batch={batch}
            allSubjects={allSubjects}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default AttendanceForm;
