import { CopyOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Upload,
} from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CLASS_LIST, DB, ROUTES } from '../../../common/constants';
import {
  beforeUpload,
  beforeUploadImage,
  combinePhoneNumbers,
  formValidatorRules,
  sortDataList,
} from '../../../common/utils';
import { messageContext } from '../../../components/AppContextHolder';
import { getAllDocuments } from '../../../firebase/collections/utils';

const { required } = formValidatorRules;
const { Option } = Select;

function TeamForm({
  submitLoading = false,
  form,
  title,
  onFinish,
  initialValues = null,
  setFileList,
  fileList = [],
  buttonLoading,
}) {
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState([]);
  const [numberArray, setNumberArrays] = useState([]);
  const fetchStudentsData = async () => {
    const students = await getAllDocuments(DB.STUDENTS);
    const teamMembers = await getAllDocuments(DB.TEAMS);
    const numbers = combinePhoneNumbers(students, teamMembers);
    setNumberArrays(numbers);
  };
  const fetchNotificationData = async (field = null, value = null) => {
    let data;
    if (field && value) {
      data = await getAllDocuments(DB.SUBJECTS, field, value);
    } else {
      data = await getAllDocuments(DB.SUBJECTS);
    }
    setSubjects(sortDataList(data));
  };

  useEffect(() => {
    fetchNotificationData();
    fetchStudentsData();
  }, []);

  const PhoneNumberRegex = /^\d{10,}$/;
  const validatePhoneNumber = (_, value) => {
    if (initialValues?.phoneNumber === value) {
      return Promise.resolve();
    }

    if (numberArray.includes(value)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('This phone number already exists!');
    }

    if (!value || PhoneNumberRegex.test(value)) {
      return Promise.resolve();
    }

    return Promise.resolve();
  };

  const handleChange = async ({ fileList: newFileList, file }) => {
    if (file?.status === 'removed') {
      if (file?.url?.length > 0) {
        setFileList([]);
        return;
      }
      setFileList([]);
      return;
    }
    if (beforeUploadImage(file)) {
      setFileList(newFileList);
    }
  };

  const copyToClipboard = () => {
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(initialValues?.password);
    messageContext.success('Password copied to clipboard');
  };

  const uploadButton = (
    <div>
      {buttonLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="mt-8">Upload</div>
    </div>
  );

  return (
    <Form
      form={form}
      className="sticky-action-form data-form"
      layout="vertical"
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Card
        className="ant-body-scroll"
        title={title}
        actions={[
          <div key="actionbutton" className="text-right">
            <Space>
              <Button
                size="large"
                disabled={submitLoading}
                onClick={() => navigate(ROUTES.TEAM)}
              >
                Cancel
              </Button>
              <Button
                size="large"
                type="primary"
                loading={submitLoading}
                htmlType="submit"
              >
                Save
              </Button>
            </Space>
          </div>,
        ]}
      >
        <div className="card-body-wrapper">
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={24} xl={24}>
              <Form.Item name="profilePhoto" label="Profile photo">
                <Upload
                  name="avatar"
                  listType="picture-circle"
                  className="avatar-uploader"
                  showUploadList={{
                    previewIcon: true,
                    showRemoveIcon: true,
                  }}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  fileList={fileList}
                >
                  {fileList?.length > 0 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8} xl={8}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ ...required, message: 'Please enter name!' }]}
              >
                <Input size="large" placeholder="Enter Name" />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8} xl={8}>
              <Form.Item name="designation" label="Education">
                <Input size="large" placeholder="Enter Education " />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8} xl={8}>
              <Form.Item
                name="subject"
                label="Subject"
                rules={[
                  {
                    ...required,
                    message: 'Please select exam subject!',
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select Subject"
                  showSearch
                  mode="multiple"
                >
                  {map(subjects, (item) => (
                    <Option key={item?.id}>{item.title}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8} xl={8}>
              <Form.Item
                name="class"
                label="Assigned Class"
                rules={[
                  {
                    ...required,
                    message: 'Please select exam class!',
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select Class"
                  showSearch
                  mode="multiple"
                >
                  {map(CLASS_LIST, (item) => (
                    <Option key={item?.key}>{item.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={8} xl={8}>
              <Form.Item
                name="address"
                label="Address"
                rules={[{ ...required, message: 'Please enter address!' }]}
              >
                <Input size="large" placeholder="Enter Address" />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8} xl={8}>
              <Form.Item
                name="phoneNumber"
                label="Phone"
                rules={[
                  {
                    validator: validatePhoneNumber,
                  },
                  { required: true, message: 'Please enter phone!' },
                ]}
              >
                <Input size="large" placeholder="Enter Phone" />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8} xl={8}>
              <Form.Item name="email" label="Email">
                <Input type="email" size="large" placeholder="Enter Email" />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8} xl={8}>
              <Form.Item name="experience" label="Experience">
                <Input
                  type="number"
                  size="large"
                  placeholder="Enter Experience"
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8} xl={8}>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Please enter password!' }]}
              >
                <Input
                  size="large"
                  addonAfter={<CopyOutlined onClick={copyToClipboard} />}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8} xl={8}>
              <Form.Item
                name="isVisible"
                label="Is visible"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Card>
    </Form>
  );
}

export default TeamForm;
