import { NotificationFilled } from '@ant-design/icons';
import { Avatar, Badge, Image } from 'antd';
import React from 'react';

function NotificationCard({ notification = null }) {
  return (
    <Badge.Ribbon text={notification?.time} color="var(--blue2)">
      <div className="notification-card mb-16">
        <div className="header d-flex align-center gap-8">
          <div className="d-flex gap-8 align-center">
            {notification?.image ? (
              <Image
                src={notification?.image}
                style={{ width: '50px' }}
                alt="image"
                className="notification-image"
              />
            ) : (
              <Avatar size={50} icon={<NotificationFilled />} />
            )}
          </div>
          <div className="ml-6">
            <div className="font-600">{notification?.title}</div>
            <div className="notification-body">{notification?.description}</div>
          </div>
        </div>
      </div>
    </Badge.Ribbon>
  );
}

export default NotificationCard;
