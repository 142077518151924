import { Button, Card, Form, Input, Spin } from 'antd';
import React, { useState } from 'react';
import logoImage from '../../assets/images/logo.jpeg';
import { COMPANY, ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { signUpWithEmailPassword } from '../../firebase/collections/auth';
import useRouter from '../../hooks/useRouter';

const { required, email } = formValidatorRules;

const Signup = () => {
  const { navigate } = useRouter();
  const [loading, setLoading] = useState(false);
  // const { initializeAuth } = useContext(AppContext);

  // function successCallback(accessToken, userData, refreshToken) {
  //   initializeAuth(accessToken, userData, refreshToken);
  //   navigate('/', { replace: true });
  // }

  // const handleGoogleSignIn = async () => {
  //   const data = await signInWithGooglePopup();
  //   if (data?.user?.accessToken) {
  //     const accessToken = data?.user?.accessToken;
  //     if (accessToken) {
  //       successCallback(accessToken, data?.userDetails);
  //     }
  //   }
  // };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase() || '',
        firstName: values?.firstName?.trim() || '',
        lastName: values?.lastName?.trim() || '',
        password: values?.password?.trim() || '',
      };
      const response = await signUpWithEmailPassword(formValues);

      if (response) {
        setLoading(false);
        navigate(ROUTES?.LOGIN, { replace: true });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={loading} wrapperClassName="full-width">
            <div className="text-center mb-32 login-logo-container">
              <img src={logoImage} alt="logo" />
            </div>
            <Form
              name="Signup"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
            >
              <Form.Item name="firstName">
                <Input placeholder="Enter First Name" />
              </Form.Item>
              <Form.Item name="lastName">
                <Input placeholder="Enter Last Name" />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[{ required, message: 'Please enter email!' }, email]}
              >
                <Input placeholder="Enter email" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required, message: 'Please enter password!' }]}
              >
                <Input.Password placeholder="Enter password" />
              </Form.Item>

              <Form.Item className="full-width mb-16">
                <Button type="primary" className="full-width" htmlType="submit">
                  Signup
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <div className="text-center login-copyrights">
          {`${COMPANY.NAME} © ${new Date().getFullYear()}`}
        </div>
      </div>
    </div>
  );
};

export default Signup;
