/* eslint-disable no-restricted-syntax */
// MarksheetPage.js
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import dayjs from 'dayjs';
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { map, orderBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { CLASS_LIST, DB, defaultDateFormat } from '../../common/constants';
import { sortDataList } from '../../common/utils';
import { messageContext } from '../../components/AppContextHolder';
import { db } from '../../firebase';
import {
  getAllDocuments,
  getDocumentsByQuery,
} from '../../firebase/collections/utils';
import useUserPermission from '../../hooks/usePermission';

const { Option } = Select;

const Marksheet = () => {
  const { isFaculty, isAdmin, permissionLoading } = useUserPermission();
  const { authUser, subjects: allSubjects } = useContext(AppContext);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const [students, setStudents] = useState(null);
  const [marksData, setMarksData] = useState({});
  const [form] = Form.useForm();
  const [loadingMarks, setLoadingMarks] = useState(false);
  const [wait, setWait] = useState(false);
  const [subjects, setSubjects] = useState(sortDataList(allSubjects));
  const [classes, setClasses] = useState(CLASS_LIST);
  const fetchdata = async () => {
    if (isAdmin() && !permissionLoading) {
      const data = await getAllDocuments(DB.SUBJECTS);
      setSubjects(data);
    }
    if (isFaculty() && !permissionLoading) {
      setClasses(authUser?.class?.map((c) => CLASS_LIST?.[c]));
      const assignedSubjects = allSubjects?.filter((subject) =>
        authUser?.subject?.includes(subject.id),
      );
      setSubjects(assignedSubjects);
    }
  };

  useEffect(() => {
    fetchdata();
  }, [permissionLoading]);

  useEffect(() => {
    form.resetFields(['exam']);
    setSelectedExam(null);
    if (selectedClass && selectedSubject) {
      getDocumentsByQuery(DB.EXAMS, [
        { field: 'examClass', operator: '==', value: selectedClass },
        { field: 'examSubject', operator: '==', value: selectedSubject },
      ]).then((res) => {
        setExams(res);
      });
    }
  }, [selectedClass, selectedSubject]);

  useEffect(() => {
    if (selectedClass) {
      setLoadingMarks(true);
      getDocumentsByQuery(DB.STUDENTS, [
        { field: 'currentClass', operator: '==', value: selectedClass },
      ]).then((res) => {
        setStudents(orderBy(res, 'name'));
        setLoadingMarks(false);
      });
    }
  }, [selectedClass]);

  useEffect(() => {
    // Fetch marks data when selected exam changes
    if (selectedExam && students.length > 0) {
      setLoadingMarks(true);
      const fetchData = async () => {
        const marksCollectionRef = collection(db, DB.MARKS);
        const q = query(
          marksCollectionRef,
          where('examId', '==', selectedExam),
          where('subjectId', '==', selectedSubject),
        );

        try {
          const querySnapshot = await getDocs(q);
          const fetchedMarksData = {};

          querySnapshot.forEach((docArg) => {
            const { studentId, marks } = docArg.data();
            fetchedMarksData[studentId] = marks;
          });
          setMarksData(fetchedMarksData);
          setLoadingMarks(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          messageContext.error('Error fetching marks data');
        }
      };

      fetchData();
    }
  }, [selectedExam, students, selectedSubject]);

  const handleSaveMarks = async () => {
    setWait(true);
    if (!selectedExam) {
      messageContext.warning('Please select exam!');
      setWait(false);
    }
    let success = false;
    // eslint-disable-next-line guard-for-in
    for (const studentId in marksData) {
      const marks = marksData[studentId];
      const examId = selectedExam;
      const subjectId = selectedSubject;
      const docRef = doc(
        collection(db, 'marks'),
        `${examId}_${studentId}_${subjectId}`,
      );

      try {
        // eslint-disable-next-line no-await-in-loop
        await setDoc(docRef, {
          examId,
          studentId,
          subjectId,
          selectedClass,
          marks,
          timestamp: new Date(),
        });
        success = true;
        setWait(false);
      } catch (error) {
        messageContext.error('something went wrong. please try again');
      }
    }
    if (success) {
      messageContext.success('Marks added!');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Exam Date',
      dataIndex: 'examDate',
      key: 'examDate',
      render: () => {
        const currentExam = exams?.filter(
          (item) => item.id === selectedExam,
        )?.[0];
        return dayjs(currentExam?.examDate?.toDate()).format(defaultDateFormat);
      },
    },
    {
      title: 'Add marks',
      key: 'addMarks',
      render: (_, student) => (
        <Input
          size="large"
          value={marksData[student.id]}
          onChange={(e) => {
            const newMarksData = { ...marksData };
            newMarksData[student.id] = e.target.value;
            setMarksData(newMarksData);
          }}
          disabled={!selectedSubject || !selectedExam}
        />
      ),
    },
  ];

  // const currentSubject = useMemo(
  //   () => filter(allSubjects, (item) => item.id === selectedSubject)?.[0],
  //   [selectedSubject, allSubjects],
  // );

  // const currentExam = filter(exams, (exam) => exam.id === selectedExam)?.[0];

  // const excelData = map(students, (student) => {
  //   const marks = marksData?.[student?.id];
  //   return {
  //     Name: student.name,
  //     Class: CLASS_LIST?.[selectedClass]?.label,
  //     Subject: currentSubject?.title,
  //     Attendance: marks,
  //     Exam: currentExam?.title || '',
  //     'Exam date': dayjs(currentExam?.examDate?.toDate()).format(
  //       defaultDateFormat,
  //     ),
  //   };
  // });

  return (
    <>
      <div className="mb-16 d-flex justify-between align-center">
        <h4 className="text-header">MarkSheet</h4>
      </div>
      <Card
        className="ant-body-scroll"
        actions={[
          <div key="actionbutton" className="text-right">
            <Space>
              <Button
                type="primary"
                size="large"
                onClick={handleSaveMarks}
                loading={wait}
              >
                Save
              </Button>
            </Space>
          </div>,
        ]}
      >
        <div className="card-body-wrapper">
          <Form layout="vertical" form={form}>
            <Row gutter={[32]}>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item label="Class">
                  <Select
                    size="large"
                    placeholder="Select Class"
                    showSearch
                    onChange={(value) =>
                      setSelectedClass && setSelectedClass(value)
                    }
                  >
                    {map(classes, (item) => (
                      <Option key={item?.key}>{item.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item name="examSubject" label="Subject">
                  <Select
                    size="large"
                    placeholder="Select Subject"
                    showSearch
                    onChange={(value) =>
                      setSelectedSubject && setSelectedSubject(value)
                    }
                  >
                    {map(subjects, (item) => (
                      <Option key={item?.id}>{item.title}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item name="exam" label="Exam">
                  <Select
                    size="large"
                    placeholder="Select Exam"
                    showSearch
                    defaultValue={null}
                    onChange={setSelectedExam}
                    disabled={!selectedClass || !selectedSubject}
                  >
                    {map(exams, (item) => (
                      <Option key={item?.id}>{item.title}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {selectedClass && (
                <>
                  {/* {selectedSubject && selectedExam && (
                    <Col span={24} className="mb-16 d-flex justify-end">
                      <ExcelExport
                        data={excelData}
                        fileName="student-marksheet"
                      />
                    </Col>
                  )} */}

                  <Col span={24}>
                    <Table
                      columns={columns}
                      dataSource={students}
                      pagination={false}
                      loading={loadingMarks}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </div>
      </Card>
    </>
  );
};

export default Marksheet;
