import { Form, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DB, ROUTES } from '../../common/constants';
import { messageContext } from '../../components/AppContextHolder';
import {
  getAllDocuments,
  getDataByDocumentId,
  updateDataWithFile,
} from '../../firebase/collections/utils';
import StudentForm from './Components/StudentForm';

function EditStudent() {
  const navigate = useNavigate();
  const [form] = Form?.useForm();
  const [fileList, setFileList] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState([]);
  const { id } = useParams();
  const [exams, setExams] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);

  const fetchData = async () => {
    const data = await getDataByDocumentId(DB.STUDENTS, id);
    setStudent(data);
    setSelectedClass(data?.currentClass);
    setFileList(
      data?.profilePhoto
        ? [{ url: data?.profilePhoto, title: 'profile photo' }]
        : [],
    );
    setLoading(false);
  };

  const fetchStudentExams = async (field, value) => {
    const data = await getAllDocuments(DB.EXAMS, field, value);
    if (data) {
      setExams(data);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    if (student?.currentClass) {
      fetchStudentExams('examClass', selectedClass);
    }
  }, [selectedClass]);

  const onFinish = async (values) => {
    const { profilePhoto, id: notInUsed, ...restValues } = values;

    setButtonLoading(true);
    try {
      const success = await updateDataWithFile(
        DB.STUDENTS,
        id,
        {
          ...restValues,
          address: restValues?.address || '',
          fatherName: restValues?.fatherName || '',
          motherName: restValues?.motherName || '',
        },
        fileList?.length > 0 ? fileList?.[0]?.originFileObj : null,
      );

      if (success) {
        messageContext.success('Student updated successfully!');
        navigate(ROUTES.VIEW_STUDENTS);
        setButtonLoading(false);
      } else {
        setButtonLoading(false);
        message?.error('Failed to update student. Please try again.');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error upadting student:', error.message);
      message?.error('Failed to update student. Please try again.');
    }
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <>
      <div className="mb-16 d-flex justify-between align-center">
        <h4 className="text-header">Edit student</h4>
      </div>
      <div className="full-height">
        <StudentForm
          form={form}
          onFinish={onFinish}
          setFileList={setFileList}
          fileList={fileList}
          buttonLoading={buttonLoading}
          initialValues={student}
          exams={exams}
          setSelectedClass={setSelectedClass}
        />
      </div>
    </>
  );
}

export default EditStudent;
