import { CopyOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Upload,
} from 'antd';
import { capitalize, map } from 'lodash';
import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CLASS_LIST, DB, REGEX } from '../../../common/constants';
import {
  beforeUpload,
  beforeUploadImage,
  combinePhoneNumbers,
  formValidatorRules,
  handleProtectedNavigation,
} from '../../../common/utils';
import { messageContext } from '../../../components/AppContextHolder';
import RouterPrompt from '../../../components/RouterPrompt';
import { getAllDocuments } from '../../../firebase/collections/utils';
import useRouter from '../../../hooks/useRouter';
import StudentExamList from './StudentExamList';

const { Option } = Select;
const { required, name } = formValidatorRules;

function StudentForm({
  form,
  onFinish,
  setFileList,
  fileList = [],
  buttonLoading,
  title = '',
  initialValues = null,
  exams = [],
  setSelectedClass,
}) {
  const { id } = useParams();
  const [showPrompt, setShowPrompt] = useState(false);
  const [isPrompt, setIsPrompt] = useState(false);
  const { navigate } = useRouter();
  const [schools, setSchools] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [numberArray, setNumberArrays] = useState([]);
  const fetchStudentsData = async () => {
    const students = await getAllDocuments(DB.STUDENTS);
    const teamMembers = await getAllDocuments(DB.TEAMS);
    const numbers = combinePhoneNumbers(students, teamMembers);
    setNumberArrays(numbers);
  };

  const copyToClipboard = (type) => {
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(initialValues?.[type]);
    messageContext.success(`${capitalize(type)} copied to clipboard`);
  };

  const handleChange = async ({ fileList: newFileList, file }) => {
    if (file?.status === 'removed') {
      setFileList([]);
      return;
    }
    if (beforeUploadImage(file)) {
      setFileList(newFileList);
    }
  };

  const handleBack = () => {
    setIsPrompt(!handleProtectedNavigation(!showPrompt, navigate, -1));
  };

  const handleShowPrompt = () => {
    setShowPrompt(true);
  };

  const handleOk = () => {
    handleProtectedNavigation(true, navigate, -1);
  };

  const handleClose = () => {
    setIsPrompt(false);
  };

  useEffect(() => {
    (async () => {
      const data = await getAllDocuments(DB.SCHOOLS);
      setSchools(data);
    })();
    fetchStudentsData();
  }, []);

  const uploadButton = (
    <div>
      {buttonLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="mt-8">Upload</div>
    </div>
  );

  const PhoneNumberRegex = /^\d{10,}$/;

  // eslint-disable-next-line no-unused-vars
  const validatePhoneNumber = (_, value) => {
    // if (initialValues?.phoneNumber === value) {
    //   return Promise.resolve();
    // }

    // if (numberArray.includes(value)) {
    //   // eslint-disable-next-line prefer-promise-reject-errors
    //   return Promise.reject('This phone number already exists!');
    // }

    if (!value || PhoneNumberRegex.test(value)) {
      return Promise.resolve();
    }

    return Promise.resolve();
  };

  const validatePercentage = (_, value) => {
    if (value > 100) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Percentage can not be greater than 100');
    }
    if (!value || REGEX.NUMBER.test(value) || value <= 100) {
      return Promise.resolve();
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('Please enter a valid number!');
  };

  const validatePhoneNumbers = (_, value) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    if (!value) {
      return Promise.reject(new Error('Please input your phone number!'));
    }
    if (!phoneRegex.test(value)) {
      return Promise.reject(
        new Error('Please enter a valid Indian phone number!'),
      );
    }
    return Promise.resolve();
  };

  return (
    <>
      <Form
        form={form}
        className="sticky-action-form data-form"
        onFieldsChange={handleShowPrompt}
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Card
          className="ant-body-scroll"
          title={title}
          actions={[
            <div key="actionbutton" className="text-right">
              <Space>
                <Button
                  size="large"
                  onClick={handleBack}
                  disabled={buttonLoading}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  type="primary"
                  loading={buttonLoading}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Space>
            </div>,
          ]}
        >
          <div className="card-body-wrapper">
            <Row gutter={[16, 0]}>
              <Col xs={24} lg={24} xl={24}>
                <Form.Item name="profilePhoto" label="Photo">
                  <Upload
                    name="avatar"
                    listType="picture-circle"
                    className="avatar-uploader"
                    showUploadList={{
                      previewIcon: true,
                      showRemoveIcon: true,
                    }}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    fileList={fileList}
                  >
                    {fileList?.length > 0 ? null : uploadButton}
                  </Upload>
                </Form.Item>
              </Col>
              {initialValues?.id && (
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item label="Student id" name="loginId">
                    <Input
                      disabled
                      size="large"
                      addonAfter={
                        <CopyOutlined
                          onClick={() => copyToClipboard('loginId')}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
              )}

              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ ...required, message: 'Please enter name!' }, name]}
                >
                  <Input size="large" placeholder="Enter Name" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone"
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                    {
                      validator: validatePhoneNumbers,
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter Phone" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="alternatePhoneNumber"
                  label="Alternate Phone"
                  rules={[
                    {
                      validator: validatePhoneNumbers,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('phoneNumber') !== value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('Alternate phone number can not be same!'),
                        );
                      },
                    }),
                  ]}
                  dependencies={['phoneNumber']}
                >
                  <Input size="large" placeholder="Enter Alternate Phone" />
                </Form.Item>
              </Col>

              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="schoolName"
                  label="School Name"
                  rules={[
                    {
                      ...required,
                      message: 'Please select school!',
                    },
                  ]}
                >
                  <Select size="large" placeholder="Select School" showSearch>
                    {map(schools, (school) => (
                      <Option key={school?.id}>{school?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="currentClass"
                  label="Current Class"
                  rules={[
                    {
                      ...required,
                      message: 'Please select current class!',
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder="Select Class"
                    showSearch
                    onChange={(value) =>
                      setSelectedClass && setSelectedClass(value)
                    }
                  >
                    {map(CLASS_LIST, (item) => (
                      <Option key={item?.key}>{item.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="marksheet"
                  label="Select Marksheet (Std.)"
                  rules={[
                    {
                      ...required,
                      message: 'Please select marksheet!',
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder="Select Marksheet"
                    showSearch
                  >
                    {map(new Array(12).fill(null), (data, idx) => (
                      <Option key={1} value={idx + 1}>
                        {idx + 1}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="previousYearPercentage"
                  label="Previous Year Percentage"
                  rules={[
                    {
                      validator: validatePercentage,
                    },
                    {
                      required: true,
                      message: 'Please enter percentage!',
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter Previous Year Percentage"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true }]}
                >
                  <Input
                    size="large"
                    addonAfter={
                      <CopyOutlined
                        onClick={() => copyToClipboard('password')}
                      />
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={16} xl={16}>
                <Form.Item name="address" label="Address">
                  <Input.TextArea
                    size="large"
                    rows={3}
                    placeholder="Enter Address"
                  />
                </Form.Item>
              </Col>
              <Divider>Parents Information</Divider>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item name="fatherName" label="Father’s name ">
                  <Input
                    size="large"
                    placeholder="Please enter Father's name"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item name="motherName" label="Mother's name ">
                  <Input
                    size="large"
                    placeholder="Please enter Mother's name"
                  />
                </Form.Item>
              </Col>
            </Row>
            {id && <StudentExamList exams={exams} />}
          </div>
        </Card>
      </Form>
      <RouterPrompt
        isPrompt={isPrompt}
        handleOK={handleOk}
        handleCancel={handleClose}
      />
    </>
  );
}

export default StudentForm;
