// src/components/AttendancePieChart.js
import React from 'react';
import Chart from 'react-apexcharts';

const AttendancePieChart = ({ attendanceData }) => {
  const chartOptions = {
    title: {
      text: 'Attendance Report:',
    },
    chart: {
      type: 'pie',
      height: 350,
    },
    series: attendanceData?.values,
    labels: attendanceData?.labels,
    dataLabels: {
      formatter(val, opts) {
        return opts.w.config.series?.[opts.seriesIndex];
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  if (!attendanceData) return null;

  return (
    <Chart
      options={chartOptions}
      series={chartOptions?.series}
      type="pie"
      height={350}
    />
  );
};

export default AttendancePieChart;
