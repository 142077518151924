import { useLocation } from 'react-router-dom';

function useQueryString() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const queryObj = Object?.fromEntries([...query.entries()]);

  return queryObj;
}

export default useQueryString;
