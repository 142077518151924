/* eslint-disable no-unused-vars */
import { Card, Col, Divider, Row, Select, Statistic } from 'antd';
import { capitalize, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  CLASS_LIST,
  DB,
  GUTTER_VARIATIONS,
  ROLE_KEYS,
} from '../../common/constants';
import { sortDataList } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import { getAllDocuments } from '../../firebase/collections/utils';
import useUserPermission from '../../hooks/usePermission';
import Permission from '../../permission/Permission';
import Statstics from './components/Statstics';

const { Option } = Select;

function ProgressModule() {
  const { isStudent, permissionLoading } = useUserPermission();
  const { state, subjects } = useContext(AppContext);
  const [student, setStudent] = useState(null);
  const [exams, setExams] = useState([]);
  const [marks, setMarks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [students, setStudents] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState('MORNING');
  const fetchStudentsData = async (field = null, value = null) => {
    let data;
    if (field && value) {
      data = await getAllDocuments(DB.STUDENTS, field, value);
    } else {
      data = await getAllDocuments(DB.STUDENTS);
    }
    setStudents(sortDataList(data));
  };

  useEffect(() => {
    if (selectedClass) {
      fetchStudentsData('currentClass', selectedClass);
    }
  }, [selectedClass]);

  const handleOnChange = async (id) => {
    setLoading(true);
    if (id) {
      const res = await getAllDocuments(DB.STUDENTS, 'id', id);
      if (res?.length === 0) {
        setMarks([]);
        setExams([]);
        setStudent(null);
        setLoading(false);
        return;
      }
      const examRes = await getAllDocuments(
        DB.EXAMS,
        'examClass',
        res?.[0]?.currentClass,
      );
      const marksRes = await getAllDocuments(
        DB.MARKS,
        'studentId',
        res?.[0]?.id,
      );
      setMarks(marksRes);
      setExams(examRes);
      setStudent(res?.[0]);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedStudentId) {
      setLoading(true);
      handleOnChange(selectedStudentId);
    }
  }, [permissionLoading, selectedStudentId]);

  useEffect(() => {
    if (isStudent()) {
      setSelectedStudentId(state?.currentUser?.id);
    }
  }, [selectedStudentId, isStudent]);

  return (
    <LoaderComponent spinning={loading}>
      <>
        <div className="page-bg p-16 mb-24">
          {student && isStudent() && (
            <>
              <Row
                gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}
                className="mt-16"
              >
                <Col xs={24} lg={8}>
                  <Card>
                    <Statistic
                      title="Your class"
                      value={
                        CLASS_LIST?.[state?.currentUser?.currentClass]?.label
                      }
                    />
                  </Card>
                </Col>
                <Col xs={24} lg={8}>
                  <Card>
                    <Statistic
                      title="Total exams attended"
                      value={marks?.length}
                    />
                  </Card>
                </Col>
                <Col xs={24} lg={8}>
                  <Card>
                    <Statistic
                      title="Total exams conducted"
                      value={exams?.length}
                    />
                  </Card>
                </Col>
              </Row>
              <Divider />
            </>
          )}

          <Row
            gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}
            className="mt-16"
          >
            <Permission
              allowedRoles={[
                ROLE_KEYS.ADMIN,
                ROLE_KEYS.SUPER_ADMIN,
                ROLE_KEYS.FACULTY,
              ]}
            >
              <Col xs={24} lg={8} xl={8}>
                <Select
                  size="large"
                  placeholder="Select Class"
                  showSearch
                  onChange={(value) => {
                    setSelectedClass(value);
                    setSelectedStudentId(null);
                  }}
                  className="w-full"
                  allowClear
                >
                  {map(CLASS_LIST, (item) => (
                    <Option key={item?.key}>{item.label}</Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} lg={8} xl={8}>
                <Select
                  size="large"
                  placeholder="Select Student"
                  showSearch
                  onChange={(value) => {
                    setSelectedStudentId(value);
                    const filterdData = students.filter((s) => s?.id === value);
                    setSelectedStudent(filterdData);
                    setSelectedBatch(null);
                  }}
                  className="w-full"
                  disabled={!selectedClass}
                  allowClear
                  value={selectedStudentId}
                >
                  {map(students, (item) => (
                    <Option key={item?.id}>{item.name}</Option>
                  ))}
                </Select>
              </Col>
              {/* <Col xs={24} lg={8} xl={8}>
                <Select
                  size="large"
                  placeholder="Select Subject"
                  showSearch
                  className="w-full"
                  onChange={(value) => {
                    setSelectedSubject(value);
                  }}
                  disabled={!selectedStudentId}
                >
                  {map(subjects, (item) => (
                    <Option key={item?.id}>{item.title}</Option>
                  ))}
                </Select>
              </Col> */}
            </Permission>
            <Col xs={24} lg={8} xl={8}>
              <Select
                size="large"
                placeholder="Select Batch"
                showSearch
                className="w-full"
                value={selectedBatch}
                onChange={(value) => {
                  setSelectedBatch(value);
                }}
                disabled={!selectedStudentId}
              >
                {map(['MORNING', 'EVENING'], (item) => (
                  <Option key={item}>{capitalize(item)}</Option>
                ))}
              </Select>
            </Col>
            {selectedStudentId && (
              <Col xl={24}>
                <h5 className="mb-16">Progress</h5>
                <Divider />
                <Statstics
                  selectedClass={selectedClass}
                  selectedStudentId={selectedStudentId}
                  user={selectedStudent?.[0] || state?.currentUser}
                  selectedSubject={selectedSubject}
                  selectedBatch={selectedBatch}
                />
              </Col>
            )}
          </Row>
        </div>
      </>
    </LoaderComponent>
  );
}

export default ProgressModule;
