import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { auth, db, googleProvider } from '..';
import { DB } from '../../common/constants';
import { messageContext } from '../../components/AppContextHolder';
import { createUser, getLoggedUserDetails } from './users';

// Sign in with Google popup
export const signInWithGooglePopup = async () => {
  try {
    const { user } = await signInWithPopup(auth, googleProvider);
    const splitName = user?.displayName?.split(' ');
    const firstName = splitName?.[0] || '';
    const lastName = splitName?.[1] || '';
    const userData = {
      firstName,
      lastName,
      profilePhoto: user.photoURL || '',
      email: user?.email,
      id: user.uid,
      // add new data as per requirement
    };
    await createUser(DB?.USERS, userData, user.uid);
    const userDetails = await getLoggedUserDetails(DB?.USERS, user?.uid);
    return { user, userDetails };
  } catch (error) {
    messageContext?.error(error?.message);
  }
};

export const signUpWithEmailPassword = async (data) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      data?.email,
      data?.password,
    );

    const { user } = userCredential;

    if (user) {
      const userData = {
        ...data,
        profilePhoto: data?.profilePhoto || '',
        id: user.uid,
      };
      const userAdded = await createUser(DB?.USERS, userData, user?.uid);
      await updateProfile(auth?.currentUser, {
        displayName: `${data?.firstName?.trim()} ${data?.lastName?.trim()}`,
      });
      if (userAdded) {
        const userDetails = await getLoggedUserDetails(DB?.USERS, user?.uid);
        return userDetails;
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    const errorMessage = error.message;
    messageContext?.error(errorMessage);
  }
};

export const signInWithEmailPassword = async ({ email, password }) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );
    return userCredential;
  } catch (error) {
    const errorMessage = error?.message;
    messageContext?.error(errorMessage);
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const logout = async () => {
  try {
    await auth?.signOut();
    return true;
  } catch (error) {
    return false;
  }
};

export const resetPassword = async (oobCode, newPassword) => {
  try {
    await confirmPasswordReset(auth, oobCode, newPassword);
    messageContext?.success('Password updated successfully!');
    return true;
  } catch (error) {
    messageContext?.error('Password Update error:', error.message);
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const forgotPassword = async (email) => {
  const q = query(collection(db, DB?.USERS), where('email', '==', email));
  const { size } = await getDocs(q); // check for email existence
  if (size !== 0) {
    try {
      await sendPasswordResetEmail(auth, email);
      messageContext?.success('Password reset email sent!');
      return true;
    } catch (error) {
      messageContext?.error('Password reset email error:', error?.message);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  } else {
    messageContext?.error('Email not exist!');
  }
};
