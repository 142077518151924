import { Button, Checkbox, Col, DatePicker, Row, Table, message } from 'antd';
import dayjs from 'dayjs';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { DB } from '../../../common/constants';
import { sortDataList } from '../../../common/utils';
import { db } from '../../../firebase';
import { getAllDocuments } from '../../../firebase/collections/utils';

function FacultyAttendance() {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format('YYYY-MM-DD'),
  );
  const [attendanceData, setAttendanceData] = useState({});
  const [saving, setSaving] = useState(false);

  const fetchTeam = async () => {
    try {
      const data = await getAllDocuments(DB.TEAMS);
      setTeams(sortDataList(data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const loadAttendanceData = async (date) => {
    try {
      const attendanceRef = doc(collection(db, 'faculty_attendance'), date);
      const attendanceDoc = await getDoc(attendanceRef);
      if (attendanceDoc.exists()) {
        setAttendanceData(attendanceDoc.data());
      } else {
        setAttendanceData({});
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeam();
    loadAttendanceData(selectedDate);
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setLoading(true);
    setSelectedDate(dayjs(date).format('YYYY-MM-DD'));
  };

  const handleCheckboxChange = (record, checked) => {
    setAttendanceData((prevData) => ({
      ...prevData,
      [record.id]: checked,
    }));
  };

  const save = async () => {
    setSaving(true);
    try {
      const attendanceRef = doc(
        collection(db, 'faculty_attendance'),
        selectedDate,
      );
      await setDoc(attendanceRef, attendanceData);
      setSaving(false);
      message.success('Attendance saved successfully');
    } catch (error) {
      setSaving(false);
      message.error('Failed to save attendance');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
    },
    {
      title: 'Attendance',
      key: 'attendance',
      render: (_, record) => (
        <Checkbox
          onChange={(e) => handleCheckboxChange(record, e.target.checked)}
          checked={attendanceData[record.id] ?? true}
        />
      ),
    },
  ];

  return (
    <div>
      <Row gutter={[16, 16]} className="mb-16" justify="space-between">
        <Col span={8}>
          <DatePicker
            size="large"
            className="w-full"
            defaultValue={dayjs()}
            onChange={handleDateChange}
          />
        </Col>
        <Col>
          <Button size="large" type="primary" onClick={save} loading={saving}>
            Save
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            dataSource={teams}
            columns={columns}
            loading={loading}
            rowKey={(record) => record.id}
          />
        </Col>
      </Row>
    </div>
  );
}

export default FacultyAttendance;
