/* eslint-disable no-undef */
import {
  BookOutlined,
  NotificationOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Card, Col, Divider, Row, Space, Statistic, Typography } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { map, sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DB, GUTTER_VARIATIONS, ROLE_KEYS } from '../../common/constants';
import {
  getAllDocuments,
  listenToRealTimeDocuments,
} from '../../firebase/collections/utils';
import Permission from '../../permission/Permission';
import ProgressModule from '../progress/ProgressModule';
import AttendancePieChart from './components/AttendancePieChart';
import NotificationCard from './components/NotificationCard';

dayjs.extend(relativeTime);

const { Title } = Typography;

const Dashboard = () => {
  const [notifications, setNotifications] = useState([]);
  const [students, setStudents] = useState([]);
  const [exams, setExams] = useState([]);
  const [loadingData, setLoadingData] = useState({
    students: true,
    exams: true,
  });

  const genExtra = (date) => dayjs(date?.toDate()).fromNow();

  const fetchStudentsData = async (field = null, value = null) => {
    let data;
    if (field && value) {
      data = await getAllDocuments(DB.STUDENTS, field, value);
    } else {
      data = await getAllDocuments(DB.STUDENTS);
    }
    setStudents(data);
    setLoadingData((prev) => ({ ...prev, students: false }));
  };

  const fetchExams = async () => {
    const data = await getAllDocuments(DB.EXAMS);
    setExams(data);
    setLoadingData((prev) => ({ ...prev, exams: false }));
  };

  const handleRealTimeUpdate = (updatedDocuments) => {
    let output = map(updatedDocuments, (item) => ({
      id: item?.id,
      from: item?.from,
      description: item?.description,
      time: genExtra(item?.created),
      date: item?.created,
      title: item?.title,
      image: item?.image,
    }));
    output = sortBy(output, 'date', 'desc').reverse();
    setNotifications(output);
  };

  useEffect(() => {
    const unsubscribe = listenToRealTimeDocuments(
      DB.NOTIFICATION,
      handleRealTimeUpdate,
      null,
      null,
      'asc',
      null,
      true,
    );
    fetchStudentsData();
    fetchExams();
    return () => unsubscribe();
  }, []);

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        Dashboard
      </Title>
      <div className="site-statistic-demo-card">
        <Permission allowedRoles={[ROLE_KEYS.ADMIN]}>
          <>
            <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
              <Col xs={24} lg={8}>
                <Card loading={loadingData.students}>
                  <Statistic
                    title="Total Students"
                    value={students?.length}
                    prefix={<UserOutlined />}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={8}>
                <Card loading={loadingData.students}>
                  <Statistic
                    title="Total Exams"
                    value={exams?.length}
                    prefix={<BookOutlined />}
                  />
                </Card>
              </Col>
            </Row>
            <Divider />
          </>
        </Permission>
        <Permission allowedRoles={[ROLE_KEYS.ADMIN]}>
          <div className="mb-24">
            <h5 className="mb-16">Faculty attendance analytics</h5>
            <div className="page-bg p-16 ">
              <AttendancePieChart />
            </div>
          </div>
        </Permission>
        <Permission allowedRoles={[ROLE_KEYS.ADMIN, ROLE_KEYS.STUDENT]}>
          <div>
            <h5 className="mb-16">Students analytics</h5>
            <ProgressModule />
          </div>
        </Permission>
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          <Space>
            <NotificationOutlined style={{ fontSize: '18px' }} />
            Latest Notifications
          </Space>
        </Title>
        <Row className="pt-12">
          <Col xs={24} lg={16}>
            {map(notifications, (notification) => (
              <NotificationCard
                notification={notification}
                key={notification?.id}
              />
            ))}
          </Col>
        </Row>
        {/* <Row className="pt-12">
          <Col span={16}>
            <Notifications notifications={notifications} loading={loading} />
          </Col>
        </Row> */}
      </div>
    </>
  );
};

export default Dashboard;
