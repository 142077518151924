import {
  BookOutlined,
  BuildOutlined,
  ClusterOutlined,
  FormOutlined,
  GroupOutlined,
  HomeOutlined,
  NotificationOutlined,
  PictureOutlined,
  TeamOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { MODULES, ROUTES } from '../../../common/constants';
import useUserPermission from '../../../hooks/usePermission';
import useRouter from '../../../hooks/useRouter';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

function Sidebar({ handleOverlay }) {
  const { isAdmin, isFaculty } = useUserPermission();
  const [selectedKey, setSelectedKey] = useState();
  const menuItems = [
    getItem(MODULES.DASHBOARD, ROUTES.MAIN, <HomeOutlined />),
    isAdmin() &&
      getItem(MODULES.STUDENTS, ROUTES.VIEW_STUDENTS, <TeamOutlined />),

    isAdmin() && getItem(MODULES.EXAMS, ROUTES.VIEW_EXAMS, <FormOutlined />),
    (isAdmin() || isFaculty()) &&
      getItem(
        MODULES.ATTENDANCE_MANAGEMENT,
        ROUTES.ATTENDANCE_MANAGEMENT,
        <BookOutlined />,
      ),
    (isAdmin() || isFaculty()) &&
      getItem(MODULES.MARKSHEET, ROUTES.MARKSHEET, <ClusterOutlined />),
    isAdmin() &&
      getItem(
        MODULES.NOTIFICATIONS,
        ROUTES.NOTIFICATIONS,
        <NotificationOutlined />,
      ),
    isAdmin() && getItem(MODULES.TEAM, ROUTES.TEAM, <UserAddOutlined />),
    isAdmin() && getItem(MODULES.GALLARY, ROUTES.GALLARY, <PictureOutlined />),
    isAdmin() && getItem(MODULES.SUBJECTS, ROUTES.SUBJECTS, <BookOutlined />),
    isAdmin() && getItem(MODULES.SCHOOLS, ROUTES.SCHOOLS, <BuildOutlined />),
    getItem(MODULES.COMPANY, ROUTES.COMPANY, <GroupOutlined />),
  ];

  const {
    navigate,
    location: { pathname },
  } = useRouter();

  const onMenuSelect = (e) => {
    handleOverlay();
    navigate(e?.key);
    setSelectedKey(e?.key);
  };

  useEffect(() => {
    if (pathname?.includes(ROUTES?.VIEW_EXAMS)) {
      setSelectedKey(ROUTES?.VIEW_EXAMS);
    }
    if (pathname?.includes(ROUTES?.VIEW_STUDENTS)) {
      setSelectedKey(ROUTES?.VIEW_STUDENTS);
    }
    if (pathname?.includes(ROUTES?.NOTIFICATIONS)) {
      setSelectedKey(ROUTES?.NOTIFICATIONS);
    }
    if (pathname?.includes(ROUTES?.TEAM)) {
      setSelectedKey(ROUTES?.TEAM);
    }
  }, [pathname]);

  return (
    <div className="side-bar">
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[selectedKey || pathname]}
        defaultSelectedKeys={[ROUTES?.MAIN]}
        onSelect={onMenuSelect}
        onClick={onMenuSelect}
        items={menuItems}
        className="menu-list-sidebar"
      />
    </div>
  );
}

export default Sidebar;
