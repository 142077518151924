import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DB, ROUTES } from '../../common/constants';
import { messageContext } from '../../components/AppContextHolder';
import LoaderComponent from '../../components/LoaderComponent';
import {
  getDataByDocumentId,
  updateDataWithFile,
} from '../../firebase/collections/utils';
import NotificationForm from './components/NotificationForm';

function EditNotification() {
  const { id } = useParams();
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(true);
  const [notification, setNotification] = useState(null);
  const [fileList, setFileList] = useState([]);

  const fetchData = async () => {
    const data = await getDataByDocumentId(DB.NOTIFICATION, id);
    setNotification(data);
    setFileList(
      data?.image ? [{ url: data?.image, title: 'notification photo' }] : [],
    );
    setLoadingData(false);
  };

  const onFinish = async (values) => {
    const { image, ...restValues } = values;
    setLoading(true);
    if (values) {
      const data = await updateDataWithFile(
        DB.NOTIFICATION,
        id,
        restValues,
        fileList?.length > 0 ? fileList?.[0]?.originFileObj : null,
        'image',
      );

      if (data) {
        messageContext.success('Notification created successfully!');
        setLoading(false);
        navigate(ROUTES.NOTIFICATIONS);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loadingData) {
    return <LoaderComponent spinning={loadingData} />;
  }
  return (
    <>
      <div className="mb-16 d-flex justify-between align-center">
        <h4 className="text-header">Edit notification</h4>
      </div>
      <NotificationForm
        form={form}
        onFinish={onFinish}
        loading={loading}
        initialValues={notification}
        fileList={fileList}
        setFileList={setFileList}
      />
    </>
  );
}

export default EditNotification;
