import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DB, ROUTES } from '../../common/constants';
import { messageContext } from '../../components/AppContextHolder';
import LoaderComponent from '../../components/LoaderComponent';
import {
  getDataByDocumentId,
  updateDocumentById,
} from '../../firebase/collections/utils';
import CompanyForm from './components/CompanyForm';

function EditCompany() {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [dataLoading, setDataLoading] = useState(true);
  const [company, setCompany] = useState(null);

  const fetchData = async () => {
    const data = await getDataByDocumentId(DB.COMPANY, id);
    setCompany(data);
    setDataLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onFinish = async (values) => {
    setSubmitLoading(true);
    const res = await updateDocumentById(DB.COMPANY, id, {
      ...values,
      startYear: values?.startYear?.toDate(),
    });
    if (res) {
      messageContext.success('Company updated!');
      setSubmitLoading(false);
      navigate(ROUTES.COMPANY);
    } else {
      setSubmitLoading(false);
      messageContext.error('Try again. Company not updated!');
    }
  };

  if (dataLoading) {
    return <LoaderComponent />;
  }

  return (
    <CompanyForm
      onFinish={onFinish}
      form={form}
      submitLoading={submitLoading}
      initialValues={company}
      title="Edit company"
    />
  );
}

export default EditCompany;
