/* eslint-disable no-unused-vars */
import {
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons/lib/icons';
import { Avatar, Button, Image, Popconfirm, Radio, Select, Space } from 'antd';
import dayjs from 'dayjs';
import { capitalize, find, map, uniqBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  CLASS_LIST,
  DB,
  ROUTES,
  defaultDateFormat,
} from '../../common/constants';
import { sortDataList } from '../../common/utils';
import TableComponent from '../../components/CommonTable';
import ExcelExport from '../../components/ExcelExport';
import LoaderComponent from '../../components/LoaderComponent';
import {
  deleteStudentAndRelatedData,
  getAllDocuments,
  getDocumentsByQuery,
} from '../../firebase/collections/utils';
import useUserPermission from '../../hooks/usePermission';
import './users.scss';

const monthList = [
  {
    value: 1,
    label: 'Jan',
  },
  {
    value: 2,
    label: 'Feb',
  },
  {
    value: 3,
    label: 'Mar',
  },
  {
    value: 4,
    label: 'Apr',
  },
  {
    value: 5,
    label: 'May',
  },
  {
    value: 6,
    label: 'Jun',
  },
  {
    value: 7,
    label: 'Jul',
  },
  {
    value: 8,
    label: 'Aug',
  },
  {
    value: 9,
    label: 'Sep',
  },
  {
    value: 10,
    label: 'Oct',
  },
  {
    value: 11,
    label: 'Nov',
  },
  {
    value: 12,
    label: 'Dec',
  },
];

function StudentList() {
  const { subjects } = useContext(AppContext);
  const [students, setStudents] = useState([]);
  const [schools, setSchools] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedClass, setSelectedClass] = useState(CLASS_LIST.STD_8_GUJ.key);
  const navigate = useNavigate();
  const { isAdmin } = useUserPermission();
  const [selectedMonth, setSelectedDate] = useState();
  const [attendance, setAttendance] = useState([]);
  const [attendanceFinalData, setAttendanceFinalData] = useState([]);
  const [marksFinalData, setMarksFinalData] = useState([]);
  const [batch, setBatch] = useState('MORNING');
  const [loadingAttendance, setLoadingAttendance] = useState(true);
  const fetchStudentsData = async (field = null, value = null) => {
    let data;
    if (field && value) {
      data = await getAllDocuments(DB.STUDENTS, field, value);
    } else {
      data = await getAllDocuments(DB.STUDENTS, null, null, {
        sortBy: 'desc',
        sortOn: 'created',
      });
    }
    setStudents(sortDataList(data));
    setLoadingData(false);
  };

  // const processAttendanceData = (attendanceQuerySnapshot) => {
  //   if (attendanceQuerySnapshot.length === 0) {
  //     setFinalData([]);
  //     return;
  //   }

  //   const studentAttendanceMap = {};

  //   attendanceQuerySnapshot.forEach((record) => {
  //     const date = dayjs(record.date.toDate()).format(defaultDateFormat);
  //     record.attendance.forEach((student) => {
  //       if (!studentAttendanceMap[student.id]) {
  //         const studentInfo = students.find((s) => s.id === student?.id);
  //         studentAttendanceMap[student.id] = {
  //           id: studentInfo?.loginId,
  //           name: studentInfo?.name,
  //           'Phone number': studentInfo?.phoneNumber,
  //           class: CLASS_LIST?.[studentInfo?.currentClass]?.label,
  //         };
  //       }
  //       studentAttendanceMap[student.id][date] = student?.present
  //         ? 'Present'
  //         : 'Absent';
  //     });
  //   });

  //   const finalAttendanceList = Object.keys(studentAttendanceMap).map(
  //     (key) => studentAttendanceMap[key],
  //   );
  //   setFinalData(finalAttendanceList);
  // };

  const fetchMarks = async (currentClass = '') => {
    const marksQuerySnapshot = await getDocumentsByQuery(DB.MARKS, [
      { field: 'selectedClass', operator: '==', value: currentClass },
    ]);
    return marksQuerySnapshot;
  };

  const fethcAttendanceData = async () => {
    const attendanceQuerySnapshot = await getDocumentsByQuery(DB.ATTENDANCE, [
      { field: 'month', operator: '==', value: parseInt(selectedMonth, 10) },
      { field: 'classId', operator: '==', value: selectedClass },
      { field: 'batch', operator: '==', value: batch },
    ]);
    if (attendanceQuerySnapshot?.length > 0) {
      setLoadingAttendance(false);
    } else {
      setLoadingAttendance(true);
    }
    setAttendance(attendanceQuerySnapshot);
    return attendanceQuerySnapshot;
  };

  const fetchExams = async (currentClass = '') => {
    const data = await getAllDocuments(DB.EXAMS, 'examClass', currentClass);
    return data;
  };

  const generateAttendanceReport = async () => {
    try {
      const studentsMap = {};
      students.forEach((student) => {
        studentsMap[student.id] = student;
      });
      const attendanceMap = {};
      attendance.forEach((record) => {
        record.attendance.forEach((student) => {
          if (!attendanceMap[student.id]) {
            attendanceMap[student.id] = {};
          }
          const date = dayjs(record.date.toDate()).format('YYYY-MM-DD');
          attendanceMap[student.id][date] = {
            status: student.present ? 'Present' : 'Absent',
            batch: record.batch,
          };
        });
      });
      const combinedData = students.map((item) => {
        const currentAttendance = attendanceMap[item.id];
        const attendanceObject = {};
        const currentSchool = find(
          schools,
          (sc) => sc?.id === item?.schoolName,
        );
        if (currentAttendance) {
          Object.keys(currentAttendance).forEach((date) => {
            attendanceObject.Batch = capitalize(currentAttendance[date].batch);
            attendanceObject[date] = currentAttendance[date].status;
          });
        }
        const combinedObject = {
          'Login Id': item.loginId,
          'Student Name': item.name,
          'Phone Number': item.phoneNumber,
          'Student Class': CLASS_LIST?.[item.currentClass]?.label,
          School: currentSchool?.name,
          ...attendanceObject,
        };

        return combinedObject;
      });
      return uniqBy(combinedData, 'Login Id');
    } catch (error) {
      return [];
    }
  };

  const generateMarksReport = async () => {
    try {
      const examMarksData = await fetchMarks(selectedClass);
      const exams = await fetchExams(selectedClass);
      const studentsMap = {};
      students.forEach((student) => {
        studentsMap[student.id] = student;
      });

      const combinedData = examMarksData.map((item) => {
        const student = studentsMap[item.studentId];
        const currentSubject = find(
          subjects,
          (subject) => subject?.id === item?.subjectId,
        );
        const currentExam = find(exams, (exam) => exam?.id === item.examId);

        const currentSchool = find(
          schools,
          (sc) => sc?.id === student?.schoolName,
        );

        const combinedObject = {
          'Login Id': student.loginId,
          'Student Name': student.name,
          'Phone Number': student.phoneNumber,
          'Student Class': CLASS_LIST?.[item.selectedClass]?.label,
          School: currentSchool?.name,
          'Exam Title': currentExam?.title,
          'Exam Subject': currentSubject?.title,
          'Exam Date': dayjs(currentExam?.examDate.toDate())?.format(
            defaultDateFormat,
          ),
          'Exam Total Marks': currentExam?.totalMarks,
          'Obtain Marks': item.marks,
        };

        return combinedObject;
      });
      return combinedData;
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    generateAttendanceReport().then((data) => {
      setAttendanceFinalData(data);
    });
    generateMarksReport().then((data) => {
      setMarksFinalData(data);
    });
  }, [attendance]);

  useEffect(() => {
    fethcAttendanceData();
  }, [selectedClass, selectedMonth, batch]);

  const fetchSchools = async () => {
    const data = await getAllDocuments(DB.SCHOOLS);
    setSchools(data);
  };

  const returnCurrentSchools = (schoolId) =>
    schools?.filter((school) => school?.id === schoolId)[0];

  const handleDelete = async (recordId) => {
    await deleteStudentAndRelatedData(recordId);
    fetchStudentsData('currentClass', selectedClass);
  };

  useEffect(() => {
    fetchStudentsData('currentClass', selectedClass);
    fetchSchools();
  }, []);

  const handleSearch = (confirm, dataIndex) => {
    setLoadingData(true);
    fetchStudentsData(dataIndex, selectedClass);
    fethcAttendanceData();
    confirm();
  };
  const handleReset = (clearFilters) => {
    setLoadingData(true);
    setSelectedClass(null);
    fetchStudentsData();
    clearFilters();
  };

  const getColumnFilterProps = (dataIndex) => ({
    filterDropdown: ({ confirm }) => (
      <div className="p-10">
        <Radio.Group
          onChange={(e) => setSelectedClass(e.target.value)}
          value={selectedClass}
          className="d-flex flex-vertical gap-6"
          style={{ maxHeight: '240px', overflowY: 'auto' }}
        >
          {map(CLASS_LIST, (option) => (
            <Radio key={option.key} value={option.key}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
        <Space className="mt-10">
          <Button
            type="primary"
            onClick={() => handleSearch(confirm, dataIndex)}
            icon={<FilterOutlined />}
          >
            Apply
          </Button>
          <Button onClick={() => handleReset(confirm)}>Reset</Button>
        </Space>
      </div>
    ),
    // onFilter: (value, record) => record[dataIndex] === value,
    filteredValue: selectedClass ? [selectedClass] : null,
  });

  const columns = [
    {
      title: 'Profile',
      dataIndex: 'profilePhoto',
      key: 'profilePhoto',
      width: 150,
      fixed: true,
      render(_, record) {
        if (record?.profilePhoto) {
          return (
            <Image
              className="profile-photo"
              src={record?.profilePhoto}
              preview
            />
          );
        }
        return <Avatar size={60} icon={<UserOutlined />} />;
      },
    },
    {
      title: 'Login id',
      dataIndex: 'loginId',
      key: 'loginId',
      render(loginid) {
        return loginid || '-';
      },
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
      render(loginid) {
        return loginid || '-';
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Alternate Phone',
      dataIndex: 'alternatePhoneNumber',
      key: 'alternatePhoneNumber',
      render(phone) {
        return phone || '-';
      },
    },
    {
      title: 'School',
      dataIndex: 'schoolName',
      key: 'schoolName',
      render(schoolId) {
        return returnCurrentSchools(schoolId)?.name;
      },
    },
    {
      title: 'Class',
      dataIndex: 'currentClass',
      key: 'currentClass',
      render(currentClass) {
        return CLASS_LIST?.[currentClass]?.label;
      },
      ...getColumnFilterProps('currentClass', 'Filter by Class'),
    },
  ];

  if (isAdmin()) {
    columns.push({
      title: '',
      render(_, record) {
        return (
          <div className="d-flex gap-6">
            <Link to={`${ROUTES.EDIT_STUDENT}/${record?.doc_id}`}>
              <Button size="small" type="link" title="edit">
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this student?"
              onConfirm={() => handleDelete(record?.doc_id)}
              okText="Yes"
              cancelText="No"
              placement="bottomRight"
            >
              <Button size="small" type="" title="delete">
                <DeleteOutlined style={{ color: 'red' }} />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    });
  }

  // const handleRowClick = (record) => {
  //   navigate(`${ROUTES.EDIT_STUDENT}/${record.doc_id}`);
  // };

  if (loadingData) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className="mb-16 d-flex justify-between align-center">
        <h4 className="text-header">
          Student List
          <h6>{CLASS_LIST?.[selectedClass]?.label}</h6>
        </h4>

        {isAdmin() ? (
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate(ROUTES.ADD_STUDENT)}
          >
            Add Student
          </Button>
        ) : (
          <div style={{ width: '200px' }} />
        )}
      </div>
      <div className="d-flex gap-8 justify-end mb-16">
        <Select
          className="w-200"
          placeholder="Select month"
          value={selectedMonth}
          onChange={(value) => {
            setSelectedDate(value);
          }}
          options={monthList}
          allowClear
        >
          {/* {monthNumbers.map((item) => (
            <Select.Option key={item}>{item}</Select.Option>
          ))} */}
        </Select>
        <Select
          onChange={(value) => {
            setBatch(value);
            setLoadingAttendance(true);
          }}
          value={batch}
          placeholder="Please select batch"
        >
          <Select.Option key="MORNING">Morning</Select.Option>
          <Select.Option key="EVENING">Evening</Select.Option>
        </Select>
        <ExcelExport
          title="Export Attendance"
          data={
            students?.length === 0 || loadingAttendance
              ? []
              : attendanceFinalData
          }
          fileName={`${capitalize(
            batch,
          )}-batch-attendance-report-month-${selectedMonth}`}
        />
        <ExcelExport
          title="Export Marksheet"
          data={
            students?.length === 0 || loadingAttendance ? [] : marksFinalData
          }
          fileName={`marks-report-of-month-${selectedMonth}`}
        />
      </div>
      <div className="page-bg student-list">
        <TableComponent
          columns={columns}
          data={students}
          loadingData={loadingData}
          // onRow={(record) => ({
          //   onClick: (event) => {
          //     // Exclude the action column click
          //     const isActionColumn = event.target.closest('.ant-btn');
          //     if (!isActionColumn) {
          //       handleRowClick(record);
          //     }
          //   },
          // })}
        />
      </div>
    </>
  );
}

export default StudentList;
