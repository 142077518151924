import { Form } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DB, ROUTES } from '../../common/constants';
import { messageContext } from '../../components/AppContextHolder';
import { createDataWithFile } from '../../firebase/collections/utils';
import useAuthState from '../../hooks/useAuthState';
import NotificationForm from './components/NotificationForm';

function AddNotification() {
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuthState();
  const [fileList, setFileList] = useState([]);

  const onFinish = async (values) => {
    const { image, ...restValues } = values;
    setLoading(true);
    const from = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      id: user?.id,
    };
    if (values) {
      const data = await createDataWithFile(
        DB.NOTIFICATION,
        { ...restValues, from },
        fileList?.length > 0 ? fileList?.[0]?.originFileObj : null,
        false,
        'image',
      );
      if (data) {
        messageContext.success('Notification created successfully!');
        setLoading(false);
        navigate(ROUTES.NOTIFICATIONS);
      }
    }
  };
  return (
    <>
      <div className="mb-16 d-flex justify-between align-center">
        <h4 className="text-header">Add Notification</h4>
      </div>

      <NotificationForm
        form={form}
        onFinish={onFinish}
        loading={loading}
        fileList={fileList}
        setFileList={setFileList}
      />
    </>
  );
}

export default AddNotification;
