import { Form } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { CLASS_LIST, DB, ROUTES } from '../../common/constants';
import { returnCurrentSubject } from '../../common/utils';
import { messageContext } from '../../components/AppContextHolder';
import { createData } from '../../firebase/collections/utils';
import useAuthState from '../../hooks/useAuthState';
import ExamForm from './components/ExamForm';
import './exam.scss';

function AddExam() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuthState();
  const { subjects } = useContext(AppContext);

  const onFinish = async (values) => {
    setLoading(true);
    const { examDate, title, ...restValues } = values || {};
    const fireStoreExamDate = examDate.toDate();
    const res = await createData(DB.EXAMS, {
      ...restValues,
      title,
      examDate: fireStoreExamDate,
    });
    if (res) {
      messageContext.success('Exam added successfully!');
      const notificationRes = await createData(DB.NOTIFICATION, {
        title: 'New exam alert!',
        description: `We're pleased to inform you that ${
          returnCurrentSubject(values?.examSubject, subjects)?.title
        } Exam has been scheduled on ${dayjs(examDate).format(
          'DD/MM/YYYY',
        )} For class ${
          CLASS_LIST?.[values?.examClass]?.label
        } and the syllabus will be ${title}`,
        from: { firstName: user?.firstName, lastName: user?.lastName },
      });
      if (notificationRes) {
        setLoading(false);
        navigate(ROUTES.VIEW_EXAMS);
      }
    } else {
      setLoading(false);
      messageContext.error('Try again. Exam not created');
    }
  };

  return (
    <>
      <div className="mb-16 d-flex justify-between align-center">
        <h4 className="text-header">Add exam</h4>
      </div>
      <div className="page-bg h-full">
        <ExamForm
          form={form}
          onFinish={onFinish}
          title="Add exam"
          buttonLoading={loading}
        />
      </div>
    </>
  );
}

export default AddExam;
