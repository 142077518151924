import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { TOKEN } from '../../common/constants';
import { logout } from '../../firebase/collections/auth';

const Logout = () => {
  const { dispatch } = useContext(AppContext);
  const handleLogout = async () => {
    // eslint-disable-next-line no-undef
    if (localStorage.getItem(TOKEN)) {
      await logout();
    }
    dispatch({ type: 'LOGOUT' });
    // eslint-disable-next-line no-undef
    window.location.reload();
  };

  useEffect(() => {
    handleLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Logout;
