import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons/lib/icons';
import { Avatar, Button, Image, Popconfirm } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { CLASS_LIST, DB, ROUTES } from '../../common/constants';
import { returnCurrentSubject, sortDataList } from '../../common/utils';
import { messageContext } from '../../components/AppContextHolder';
import TableComponent from '../../components/CommonTable';
import { deleteData, getAllDocuments } from '../../firebase/collections/utils';
import useUserPermission from '../../hooks/usePermission';

function TeamList() {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { isAdmin } = useUserPermission();
  const { subjects } = useContext(AppContext);

  const fetchTeam = async (field = null, value = null) => {
    let data;
    if (field && value) {
      data = await getAllDocuments(DB.TEAMS, field, value);
    } else {
      data = await getAllDocuments(DB.TEAMS);
    }
    setTeams(sortDataList(data));
    setLoading(false);
  };

  useEffect(() => {
    fetchTeam();
  }, []);

  const handleDelete = async (recordId) => {
    const res = await deleteData(DB.TEAMS, recordId);
    if (res) {
      messageContext.success('Team member removed!');
      fetchTeam();
    }
  };

  const columns = [
    {
      title: 'Profile',
      dataIndex: 'profilePhoto',
      key: 'profilePhoto',
      width: 150,
      fixed: true,
      render(_, record) {
        if (record?.profilePhoto) {
          return (
            <Image
              className="profile-photo"
              src={record?.profilePhoto}
              preview
            />
          );
        }
        return <Avatar size={60} icon={<UserOutlined />} />;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Education',
      dataIndex: 'designation',
      key: 'designation',
      render(edu) {
        return edu || '-';
      },
    },
    {
      title: 'Assigned Class',
      dataIndex: 'class',
      key: 'class',
      render(classNames) {
        return classNames?.map((c) => CLASS_LIST?.[c]?.label).join(', ');
      },
    },
    {
      title: 'Subject(s)',
      dataIndex: 'subject',
      key: 'subject',
      render(_, record) {
        // returnCurrentSubject(subjectID, subjects)?.title || '-'}
        return record?.subject
          ?.map((item) => returnCurrentSubject(item, subjects)?.title || '-')
          ?.join(', ');
      },
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render(email) {
        return email || '-';
      },
    },
    {
      title: 'Experience',
      dataIndex: 'experience',
      key: 'experience',
      render(ex) {
        return ex || '-';
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
  ];

  if (isAdmin()) {
    columns.push({
      title: '',
      render(_, record) {
        return (
          <div className="d-flex gap-6">
            <Link to={`${ROUTES.EDIT_TEAM}/${record.id}`}>
              <Button size="small" type="link">
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this member?"
              onConfirm={() => handleDelete(record?.id)}
              okText="Yes"
              cancelText="No"
              placement="bottomRight"
            >
              <Button size="small" type="">
                <DeleteOutlined style={{ color: 'red' }} />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    });
  }

  return (
    <>
      <div className="mb-16 d-flex justify-between align-center">
        <h4 className="text-header">Member List</h4>
        {isAdmin() && (
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate(ROUTES.ADD_TEAM)}
          >
            Add Member
          </Button>
        )}
      </div>
      <div className="page-bg student-list">
        <TableComponent
          columns={columns}
          data={teams}
          loadingData={loading}
          // eslint-disable-next-line no-unused-vars
          onRow={(record) => ({
            onClick: (event) => {
              // Exclude the action column click
              const isActionColumn = event.target.closest('.ant-btn');
              if (!isActionColumn) {
                // handleRowClick(record);
              }
            },
          })}
        />
      </div>
    </>
  );
}

export default TeamList;
