import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons/lib/icons';
import { Button, Popconfirm } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { CLASS_LIST, DB, ROUTES } from '../../common/constants';
import { returnCurrentSubject, sortDataList } from '../../common/utils';
import { messageContext } from '../../components/AppContextHolder';
import TableComponent from '../../components/CommonTable';
import { deleteData, getAllDocuments } from '../../firebase/collections/utils';
import useUserPermission from '../../hooks/usePermission';
import './exam.scss';

function StudentList() {
  const { subjects } = useContext(AppContext);
  const [exams, setExams] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { isAdmin } = useUserPermission();

  const fetchExams = async () => {
    const data = await getAllDocuments(DB.EXAMS, null, null, {
      sortOn: 'title',
      sortBy: 'asc',
    });
    setExams(sortDataList(data));
    setLoading(false);
  };

  const handleDelete = async (recordId) => {
    setLoading(true);
    const res = await deleteData(DB.EXAMS, recordId);
    if (res) {
      messageContext.success('Exam removed!');
      setLoading(false);
    }
    fetchExams();
  };

  useEffect(() => {
    fetchExams();
  }, []);
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Date',
      dataIndex: 'examDate',
      key: 'examDate',
      render(date) {
        return dayjs(date?.toDate()).format('DD/MM/YYYY');
      },
    },
    {
      title: 'Class',
      dataIndex: 'examClass',
      key: 'examClass',
      render(examClass) {
        return CLASS_LIST?.[examClass]?.label;
      },
    },
    {
      title: 'Subject',
      dataIndex: 'examSubject',
      key: 'examSubject',
      render(subjectID) {
        return (
          <span>{returnCurrentSubject(subjectID, subjects)?.title || '-'}</span>
        );
      },
    },
    {
      title: 'Marks',
      dataIndex: 'totalMarks',
      key: 'totalMarks',
      render(marks) {
        return marks || '-';
      },
    },
  ];

  if (isAdmin()) {
    columns.push({
      title: '',
      render(_, record) {
        return (
          <div className="d-flex gap-6">
            <Link to={`${ROUTES.EDIT_EXAM}/${record.id}`}>
              <Button size="small" type="link" title="edit">
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this exam?"
              onConfirm={() => handleDelete(record?.id)}
              okText="Yes"
              cancelText="No"
              placement="bottomRight"
            >
              <Button size="small" type="" title="delete">
                <DeleteOutlined style={{ color: 'red' }} />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    });
  }

  return (
    <>
      <div className="mb-16 d-flex justify-between align-center align-center">
        <h4 className="text-header">Exam List</h4>
        {isAdmin() ? (
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate(ROUTES.ADD_EXAM)}
          >
            Add Exam
          </Button>
        ) : (
          <div />
        )}
      </div>
      <div className="page-bg student-list">
        {/* <SearchComponent size="large" className="mb-16" getData={handleSearch} /> */}
        <TableComponent
          columns={columns}
          data={exams}
          loadingData={loading}
          // eslint-disable-next-line no-unused-vars
          // onRow={(record) => ({
          //   onClick: (event) => {
          //     // Exclude the action column click
          //     const isActionColumn = event.target.closest('.ant-btn');
          //     if (!isActionColumn) {
          //       // handleRowClick(record);
          //     }
          //   },
          // })}
        />
      </div>
    </>
  );
}

export default StudentList;
