/* eslint-disable no-undef */
import {
  DeleteOutlined,
  EditOutlined,
  NotificationFilled,
  PlusOutlined,
} from '@ant-design/icons/lib/icons';
import { Avatar, Button, Image, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DB, ROUTES } from '../../common/constants';
import { sortDataList } from '../../common/utils';
import { messageContext } from '../../components/AppContextHolder';
import TableComponent from '../../components/CommonTable';
import { deleteData, getAllDocuments } from '../../firebase/collections/utils';

function ListNotifications() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const fetchNotificationData = async (field = null, value = null) => {
    let data;
    if (field && value) {
      data = await getAllDocuments(DB.NOTIFICATION, field, value);
    } else {
      data = await getAllDocuments(DB.NOTIFICATION);
    }
    setNotifications(sortDataList(data));
    setLoading(false);
  };

  const handleDelete = async (recordId) => {
    const res = await deleteData(DB.NOTIFICATION, recordId);
    if (res) {
      messageContext.success('Notification removed!');
    }
    fetchNotificationData();
  };

  useEffect(() => {
    fetchNotificationData();
  }, []);
  const columns = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      width: 150,
      fixed: true,
      className: 'notification-card',
      render(_, record) {
        if (record?.image) {
          return (
            <Image
              className="profile-photo notification-image"
              src={record?.image}
              preview
            />
          );
        }
        return <Avatar size={50} icon={<NotificationFilled />} />;
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '',
      render(_, record) {
        return (
          <div className="d-flex gap-6">
            <Link to={`${ROUTES.EDIT_NOTIFICATION}/${record.id}`}>
              <Button size="small" type="link" title="edit">
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this notification?"
              onConfirm={() => handleDelete(record?.id)}
              okText="Yes"
              cancelText="No"
              placement="bottomRight"
            >
              <Button size="small" type="" title="delete">
                <DeleteOutlined style={{ color: 'red' }} />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="mb-16 d-flex justify-between align-center">
        <h4 className="text-header">Notification List</h4>
        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => navigate(ROUTES.ADD_NOTIFICATION)}
        >
          Add Notification
        </Button>
      </div>
      <div className="page-bg student-list">
        <TableComponent
          columns={columns}
          data={notifications}
          loadingData={loading}
          // eslint-disable-next-line no-unused-vars
          onRow={(record) => ({
            onClick: (event) => {
              // Exclude the action column click
              const isActionColumn = event.target.closest('.ant-btn');
              if (!isActionColumn) {
                // handleRowClick(record);
              }
            },
          })}
        />
      </div>
    </>
  );
}

export default ListNotifications;
