import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { orderBy } from 'lodash';
import { db } from '..';
import { DB } from '../../common/constants';
import { generateUniqueId, showBrowserNotification } from '../../common/utils';
import { messageContext } from '../../components/AppContextHolder';

export const updateData = async (collectionName, documentId, data) => {
  const obj = {};
  Object?.assign(obj, data);
  Object?.keys(obj)?.forEach(
    (key) => obj?.[key] === undefined && delete obj?.[key],
  );
  try {
    const docRef = doc(db, collectionName, documentId);
    await setDoc(docRef, obj, { merge: true });
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error Updating:', err);
  }
};

export const createData = async (collectionName, data) => {
  const obj = {};
  Object?.assign(obj, data);
  Object?.keys(obj)?.forEach(
    (key) => obj?.[key] === undefined && delete obj?.[key],
  );
  try {
    const { id } = await addDoc(collection(db, collectionName), {
      ...obj,
      created: Timestamp?.now(),
    });
    await updateData(collectionName, id, { id });
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error Injecting:', err);
  }
};

export const createDataWithFile = async (
  collectionName,
  data,
  file,
  allowCustomId = false,
  imageKey = 'profilePhoto',
) => {
  const obj = {};
  Object.assign(obj, data);
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);

  try {
    const docRef = await addDoc(collection(db, collectionName), {
      ...obj,
      loginId: generateUniqueId('GC_'),
      created: Timestamp.now(),
    });
    let docId = docRef.id;
    if (allowCustomId) {
      docId = `GC_${docRef.id}`;
      await updateDoc(doc(db, collectionName, docRef.id), {
        id: docId,
        doc_id: docRef.id,
      });
    }

    if (file) {
      const storageRef = ref(
        getStorage(),
        `${collectionName}/${docId}/${file.name}`,
      );

      await uploadBytes(storageRef, file);

      const profilePhoto = await getDownloadURL(storageRef);
      await updateDoc(doc(db, collectionName, docRef.id), {
        [imageKey]: profilePhoto,
      });
    }

    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error creating data with file:', err);
  }
};

export const deleteData = async (
  collectionName,
  customId,
  deleteFile = false,
) => {
  try {
    const docRef = doc(db, collectionName, customId);
    await deleteDoc(docRef);
    if (deleteFile) {
      const storageRef = ref(getStorage(), `${collectionName}/${customId}`);
      await deleteObject(storageRef);
    }

    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error deleting data:', error);
    return false;
  }
};

export const deleteMultipleData = async (collectionName, documentIds = []) => {
  try {
    const result = documentIds?.map(async (id) => {
      await deleteDoc(doc(db, collectionName, id));
    });
    Promise?.all(result); // check all promise resolved inside map
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error Deleting:', err);
  }
};

export const getDataByDocumentId = async (collectionName, documentId) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);
    if (docSnap?.exists()) {
      return docSnap?.data();
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error Fetching:', err);
  }
};

export const getAllDocuments = async (
  collectionName,
  field = null,
  value = null,
  sort = { sortOn: 'created', sortBy: 'desc' },
) => {
  try {
    let collectionRef = collection(db, collectionName);

    if (field && value) {
      collectionRef = query(collectionRef, where(field, '==', value));
    }

    if (sort.sortOn) {
      collectionRef = query(collectionRef, orderBy(sort.sortOn, sort.sortBy));
    }

    const querySnapshot = await getDocs(collectionRef);

    const documents = [];
    querySnapshot.forEach((currentDoc) => {
      documents.push({ id: currentDoc.id, ...currentDoc.data() });
    });

    return documents;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching documents:', error);
    throw error; // Rethrow the error for handling by the caller
  }
};

export const updateDataWithFile = async (
  collectionName,
  documentId,
  newData,
  newFileList,
  imageKey = 'profilePhoto',
) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    const docSnapshot = await getDoc(docRef);

    if (!docSnapshot.exists()) {
      // eslint-disable-next-line no-console
      console.error(`Document with ID ${documentId} does not exist.`);
      return false;
    }

    const oldData = docSnapshot.data();
    const oldFileUrl =
      oldData[imageKey] || oldData.profilePhoto || oldData.image;

    await updateDoc(docRef, { ...newData });

    if (!newFileList && oldFileUrl) {
      const oldFileRef = ref(getStorage(), oldFileUrl);
      await deleteObject(oldFileRef);
      await updateDoc(docRef, { [imageKey]: null });
    } else if (newFileList) {
      const customId = docRef.id;
      const newFile = newFileList;
      const storageRef = ref(
        getStorage(),
        `${collectionName}/${customId}/${newFile.name}`,
      );
      await uploadBytes(storageRef, newFile);
      const newFileUrl = await getDownloadURL(storageRef);
      await updateDoc(docRef, { [imageKey]: newFileUrl });
    }

    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error');
    // eslint-disable-next-line no-console
    console.error('Error updating data with file:', error);
    return false;
  }
};

export const listenToRealTimeDocuments = (
  collectionName,
  callback,
  field = null,
  value = null,
  sortOrder = 'desc',
  limitCount = 15,
  browserAlert = false,
) => {
  let collectionRef = collection(db, collectionName);

  if (field && value) {
    collectionRef = query(collectionRef, where(field, '==', value));
  }

  // Apply orderBy based on sortOrder
  if (sortOrder === 'asc') {
    collectionRef = query(collectionRef, orderBy('createdAt'));
  } else if (sortOrder === 'desc') {
    collectionRef = query(collectionRef, orderBy('createdAt', 'desc'));
  }

  collectionRef = limitCount
    ? query(collectionRef, limit(limitCount))
    : query(collectionRef);

  const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
    const updatedDocuments = [];
    snapshot.forEach((docu) => {
      updatedDocuments.push({ id: docu.id, ...docu.data() });
    });
    if (typeof callback === 'function') {
      callback(updatedDocuments);
    }
    if (browserAlert) {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'modified') {
          const description = change.doc.data()?.title;
          if (description) {
            showBrowserNotification(description);
          }
        }
      });
    }
  });

  return unsubscribe;
};

export const updateDocumentById = async (
  collectionName,
  documentId,
  newData,
) => {
  try {
    const documentRef = doc(db, collectionName, documentId);
    await updateDoc(documentRef, newData);
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error updating document:', error);
    return false;
  }
};

export const getDocumentsByQuery = async (collectionName, queries) => {
  let collectionRef = collection(db, collectionName);

  // Apply each query condition
  queries.forEach(({ field, operator, value }) => {
    collectionRef = query(collectionRef, where(field, operator, value));
  });

  // Execute the query and return the resulting snapshot
  const querySnapshot = await getDocs(collectionRef);

  const documents = [];
  querySnapshot.forEach((currentDoc) => {
    documents.push({ id: currentDoc.id, ...currentDoc.data() });
  });

  return documents;
};

export const queryAttendanceByStudentId = async (studentId) => {
  try {
    const querySnapshot = await getDocs(collection(db, 'attendance'));
    const attendanceRecords = [];
    querySnapshot.forEach((docRes) => {
      const data = docRes.data();
      const studentAttendance = data.attendance.find(
        (record) => record.id === studentId,
      );
      if (studentAttendance) {
        attendanceRecords.push({
          ...data,
        });
      }
    });
    return attendanceRecords;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error querying attendance by studentId:', error);
    return [];
  }
};

export const removeAttendanceByStudentId = async (studentId) => {
  try {
    // Query the attendance collection
    const querySnapshot = await getDocs(collection(db, 'attendance'));

    // Iterate through each document in the attendance collection
    querySnapshot.forEach(async (docRes) => {
      const docRef = docRes.ref;
      const data = docRes.data();

      // Filter out the attendance record for the specified student
      const updatedAttendance = data.attendance.filter(
        (record) => record.id !== studentId,
      );

      // Update the document in the attendance collection with the modified attendance data
      await updateDoc(docRef, { attendance: updatedAttendance });
    });
  } catch (error) {
    return false;
  }
};

// Function to delete related data from a collection based on a field value
const deleteRelatedData = async (collectionName, field, value) => {
  try {
    const querySnapshot = await getDocs(
      query(collection(db, collectionName), where(field, '==', value)),
    );

    const batch = writeBatch(db);

    querySnapshot.forEach((docData) => {
      batch.delete(docData.ref);
    });

    await batch.commit();
  } catch (error) {
    return false;
  }
};

export const deleteStudentAndRelatedData = async (studentId) => {
  try {
    const studentDeleted = await deleteData(DB.STUDENTS, studentId);
    if (studentDeleted) {
      await removeAttendanceByStudentId(`GC_${studentId}`);
      await deleteRelatedData(DB.MARKS, 'studentId', `GC_${studentId}`);
      messageContext.success('Student deleted successfully!');
    }
  } catch (error) {
    messageContext.error('Error deleting student and related data');
  }
};

export const uploadImageToFirestore = async (file, collectionName) => {
  try {
    const storage = getStorage();
    const storageRef = ref(storage, `${collectionName}/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    await addDoc(collection(db, collectionName), {
      url: downloadURL,
      name: file.name,
      created: new Date(),
    });
    messageContext.success('Image uploaded successfully!');
    return downloadURL; // Return download URL for further use if needed
  } catch (error) {
    messageContext.error('Error uploading image');
    throw error; // Throw error for error handling in the calling code
  }
};
