/* eslint-disable no-undef */
import dayjs from 'dayjs';
import { deleteDoc, doc, getDoc } from 'firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { find } from 'lodash';
import { messageContext } from '../components/AppContextHolder';
import { db, storage } from '../firebase';
import { REGEX, defaultDateFormat } from './constants';

// Portal related methods
export const injectUsingPortal = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isPortalIdExists = (portalId) => !!injectUsingPortal(portalId);

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isDocumentIdExist = (portalId) =>
  !!getElementFromDocumentId(portalId);
// Check for document Id's exists end

export const formatDate = (
  dateTime,
  format = `${defaultDateFormat} hh:mm A`,
) => {
  if (dateTime && dayjs && format) {
    return dayjs(dateTime)?.format(format);
  }

  return dateTime;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
  },
  email: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Invalid Email');
      }
      return Promise?.resolve();
    },
  }),
  name: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid name');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    },
  }),
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj?.setUTCHours(timeObj?.getUTCHours());
  formattedDateTime = new Date(formattedDateTime)?.setUTCMinutes(
    timeObj?.getUTCMinutes(),
  );
  formattedDateTime = new Date(formattedDateTime)?.toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
  });
};

export const formItemProps = { normalize: (value) => value?.trim() };

// Note : Function to upload on s3 bucket
export async function fileUpload(signedUrl, image, onUploadProgress) {
  try {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      const xhr = new XMLHttpRequest();
      xhr?.open('PUT', signedUrl);
      xhr?.setRequestHeader('Content-Type', image?.type);
      xhr?.setRequestHeader('x-amz-acl', 'public-read');
      xhr?.addEventListener('readystatechange', function () {
        if (this?.readyState === 4) {
          resolve(xhr?.response);
        }
      });
      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => {
          let percentComplete = 0;
          percentComplete = Math?.ceil((e?.loaded / e?.total) * 100);
          onUploadProgress(percentComplete);
        };
      }
      xhr?.send(image);
    });
  } catch (error) {
    messageContext?.error(error?.message);
  }
}

export const getSignedUrl = async () =>
  // fileObj
  {
    // const fileName = fileObj?.name;

    // const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
    // const key = `${fileName}`;

    const response = false;
    if (response) {
      return response?.data;
    }
    return null;
  };

export const fetchImage = async () =>
  // fileObj
  {
    // const fileName = fileObj?.name;
    // const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
    // const key = `${fileName}`;

    const response = false;

    if (response) {
      return response?.data;
    }
    return null;
  };

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });

export const getTimeFromMins = (mins) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  return `${hours}h ${minutes}m`;
};

export const getBase64File = (img, callback) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader?.addEventListener('load', () => callback(reader?.result));
  reader?.readAsDataURL(img);
};

export const beforeUpload = () => false;

export const beforeUploadImage = (file, size = 1) => {
  const isJpgOrPng = file?.type === 'image/jpeg' || file?.type === 'image/png';
  const maxSize = size; // value in MB
  const maxSizeBytes = maxSize * 1024 * 1024; // convert to bytes
  const isSizeOk = file?.size < maxSizeBytes;

  if (!isJpgOrPng) {
    messageContext?.error('You can only upload JPG/PNG file!');
  } else if (!isSizeOk) {
    messageContext?.error(`Image must smaller than ${size}MB!`);
  }
  return isJpgOrPng && isSizeOk;
};

// firebase utils
export const checkDocumentExistence = async (collectionName, documentId) => {
  const docRef = doc(db, collectionName, documentId);
  try {
    const docSnapshot = await getDoc(docRef);
    return docSnapshot?.exists();
  } catch (error) {
    return false;
  }
};

export const uploadFileToFirebaseStorage = async (pathToStorage, fileObj) => {
  try {
    const PATH = `${pathToStorage}/${fileObj?.name}`;
    const storageRef = ref(storage, PATH);
    const snapshot = await uploadBytes(storageRef, fileObj);
    if (snapshot) {
      return await getDownloadURL(snapshot?.ref);
    }
  } catch (error) {
    messageContext?.error('Error uploading file: ', error?.message);
  }
};

// list files from the folder
export const getFileList = async (path) => {
  try {
    const listRef = ref(storage, path); // path can be /profile, /profile/user
    const fileList = await listAll(listRef);
    return fileList;
  } catch (error) {
    messageContext?.error('Error fetching files: ', error?.message);
  }
};

// delete single file
export const deleteSingleFile = async (filePath) => {
  try {
    const desertRef = ref(storage, filePath); // filePath can be /profile/test.png
    await deleteObject(desertRef);
    messageContext?.success('File deleted successfully!');
    return true;
  } catch (error) {
    messageContext?.error('Error deleting file: ', error?.message);
  }
};

// delete by document ID
export const deleteByDocumentId = async (collectionName, documentId) => {
  try {
    await deleteDoc(doc(db, collectionName, documentId));
    messageContext?.success('Record deleted successfully!');
    return true;
  } catch (error) {
    messageContext?.error('Error deleting record: ', error?.message);
  }
};

export const handleProtectedNavigation = (allow, callback, path) =>
  allow ? callback(path) : false;

export const sortDataList = (data) =>
  data.sort((a, b) => {
    // Convert the created timestamps to numbers for comparison
    const timestampA = a.created.seconds + a.created.nanoseconds / 1e9;
    const timestampB = b.created.seconds + b.created.nanoseconds / 1e9;

    // Sort in descending order
    return timestampB - timestampA;
  });

export const showBrowserNotification = (content) => {
  if (!('Notification' in window)) {
    // eslint-disable-next-line no-alert
    window.alert('This browser does not support desktop notification');
  } else {
    // eslint-disable-next-line no-new
    new Notification(content);
  }
};
export function generateRandomPassword(length) {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+';
  let password = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
}

export const returnCurrentSubject = (id, arr) => find(arr, ['id', id]);

export function combinePhoneNumbers(studentData, teamData) {
  const combinedPhoneNumbers = [];

  if (Array.isArray(studentData)) {
    studentData.forEach((student) => {
      if (student.phoneNumber) {
        combinedPhoneNumbers.push(student.phoneNumber);
      }
    });
  }

  if (Array.isArray(teamData)) {
    teamData.forEach((team) => {
      if (team.phoneNumber) {
        combinedPhoneNumbers.push(team.phoneNumber);
      }
    });
  }

  return combinedPhoneNumbers;
}

export function generateUniqueId(prefix) {
  const randomNumber = Math.floor(Math.random() * 10000000); // Generate a random number between 0 and 999999
  const uniqueId = `${prefix}${randomNumber.toString().padStart(6, '0')}`; // Pad the number with leading zeros if necessary
  return uniqueId;
}
