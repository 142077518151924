import { Button, Checkbox, Table } from 'antd';
import dayjs from 'dayjs';
import { Timestamp, collection, doc, updateDoc } from 'firebase/firestore';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DB } from '../../../common/constants';
import { messageContext } from '../../../components/AppContextHolder';
import { db } from '../../../firebase';
import {
  createData,
  getDocumentsByQuery,
} from '../../../firebase/collections/utils';

const StudentAttendanceTable = ({
  students = [],
  selectedClass,
  selectedDate,
  // selectedSubject,
  batch,
  // allSubjects,
}) => {
  const [loading, setLoading] = useState(false);
  const [attendanceArray, setAttendanceArray] = useState([]);
  const [documentAvailable, setDocumentAvilable] = useState(false);
  const [docId, setDocId] = useState(null);
  useEffect(() => {
    const byStudents = map(students, (item) => ({
      id: item?.id,
      present: true,
    }));
    setAttendanceArray(byStudents);
    const fetchAttendanceData = async () => {
      setLoading(true);
      try {
        const attendanceQuerySnapshot = await getDocumentsByQuery(
          DB.ATTENDANCE,
          [
            { field: 'date', operator: '==', value: selectedDate },
            { field: 'classId', operator: '==', value: selectedClass },
            // { field: 'subjectId', operator: '==', value: selectedSubject },
            { field: 'batch', operator: '==', value: batch },
          ],
        );
        if (attendanceQuerySnapshot?.[0]?.attendance?.length > 0) {
          const attendanceData = attendanceQuerySnapshot?.[0]?.attendance;
          setDocId(attendanceQuerySnapshot?.[0]?.id);
          setAttendanceArray(attendanceData);
          setDocumentAvilable(true);
        } else {
          setDocumentAvilable(false);
          const initialAttendanceArray = students.map((student) => ({
            id: student.id,
            present: true, // Assuming default attendance is 'false' (absent)
          }));
          setAttendanceArray(initialAttendanceArray);
        }
      } catch (error) {
        messageContext.error('Error fetching attendance data');
      }
      setLoading(false);
    };

    if (selectedClass && selectedDate && batch) {
      fetchAttendanceData();
    }
  }, [selectedClass, selectedDate, students, batch]);

  const handleAttendance = (value, record) => {
    const updatedAttendanceArray = attendanceArray.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          present: value.target.checked,
        };
      }
      return item;
    });
    setAttendanceArray(updatedAttendanceArray);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!documentAvailable) {
        await createData(DB.ATTENDANCE, {
          date: Timestamp?.fromDate(selectedDate),
          classId: selectedClass,
          attendance: attendanceArray,
          batch,
          month: dayjs(selectedDate).month() + 1,
        });
      } else {
        await updateDoc(doc(collection(db, DB.ATTENDANCE), docId), {
          attendance: attendanceArray,
          batch,
        });
      }
      setLoading(false);
      messageContext.success('Data saved successfully!');
    } catch (error) {
      messageContext.error('Error saving attendance data');
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Student Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Attendance',
      dataIndex: 'attendance',
      key: 'attendance',
      render: (_, record) => {
        const attendanceRecord = attendanceArray?.find(
          (item) => item.id === record.id,
        );
        const isChecked = attendanceRecord?.present || false;

        return (
          <Checkbox
            checked={isChecked}
            onChange={(value) => handleAttendance(value, record)}
            name={`attendance.${record.id}`}
          />
        );
      },
    },
  ];

  // const currentSubject = useMemo(
  //   () => filter(allSubjects, (item) => item.id === selectedSubject)?.[0],
  //   [selectedSubject, allSubjects],
  // );

  // const excelData = map(students, (student) => {
  //   const attendanceRecord = attendanceArray.find(
  //     (item) => item.id === student.id,
  //   );
  //   return {
  //     Name: student.name,
  //     Class: CLASS_LIST?.[selectedClass]?.label,
  //     // Subject: currentSubject?.title,
  //     Date: dayjs(selectedDate).format(defaultDateFormat),
  //     Attendance: attendanceRecord?.present ? 'Present' : 'Absent',
  //     Batch: batch,
  //   };
  // });

  if (!selectedClass) {
    return null;
  }

  return (
    <>
      <div className="d-flex justify-end">
        <Button
          onClick={handleSubmit}
          type="primary"
          loading={loading}
          className="mb-8"
        >
          Save
        </Button>
        {/* <div className="ml-16">
          <ExcelExport data={excelData} fileName="studant-attandance" />
        </div> */}
      </div>
      <Table
        dataSource={students}
        columns={columns}
        rowKey="id"
        pagination={false}
        loading={loading}
        bordered
      />
    </>
  );
};

export default StudentAttendanceTable;
