// src/components/Chart.js
import React from 'react';
import Chart from 'react-apexcharts';

const ExamWiseMarks = ({ data }) => {
  const options = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: data?.examTitles,
    },
    title: {
      text: 'Exams Report:',
    },
    dataLabels: {
      formatter(val, { dataPointIndex }) {
        return `${data?.examSubject?.[dataPointIndex]} `;
      },
    },
    tooltip: {
      y: {
        // eslint-disable-next-line no-unused-vars
        formatter(val, { series, seriesIndex, dataPointIndex, w }) {
          return `${data?.tooltipTitle?.[dataPointIndex]} (${val} / ${data?.totalMarks?.[dataPointIndex]})`;
        },
      },
    },
  };

  const series = [
    // {
    //   name: 'Total Marks',
    //   data: data?.totalMarks,
    // },
    {
      name: 'Data',
      data: data?.obtainedMarks,
    },
  ];

  return <Chart options={options} series={series} type="bar" height={350} />;
};

export default ExamWiseMarks;
