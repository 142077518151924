/* eslint-disable no-undef */
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons/lib/icons';
import { Button, Form, Input, Modal, Popconfirm, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { DB } from '../../common/constants';
import { sortDataList } from '../../common/utils';
import TableComponent from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import {
  createData,
  deleteData,
  getAllDocuments,
  getDataByDocumentId,
  updateDocumentById,
} from '../../firebase/collections/utils';

function SubjectList() {
  const [form] = Form.useForm();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [subjectId, setSubjectId] = useState('');
  const [dataLoading, setDataLoading] = useState(false);
  const [submit, setSubmit] = useState(false);

  const fetchNotificationData = async (field = null, value = null) => {
    let data;
    if (field && value) {
      data = await getAllDocuments(DB.SUBJECTS, field, value);
    } else {
      data = await getAllDocuments(DB.SUBJECTS);
    }
    setNotifications(sortDataList(data));
    setLoading(false);
  };

  const handleDelete = async (recordId) => {
    const res = await deleteData(DB.SUBJECTS, recordId);
    if (res) {
      message.info('Subject removed');
    }
    fetchNotificationData();
  };

  const handleEdit = async (id) => {
    setOpen(true);
    setIsEdit(true);
    setSubjectId(id);
    const res = await getDataByDocumentId(DB.SUBJECTS, id);
    if (res) {
      form.setFieldsValue(res);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    fetchNotificationData();
  }, []);
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '',
      render(_, record) {
        return (
          <div className="d-flex gap-6">
            <Button
              size="small"
              type="link"
              title="edit"
              onClick={() => handleEdit(record.id)}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this subject?"
              onConfirm={() => handleDelete(record?.id)}
              okText="Yes"
              cancelText="No"
              placement="bottomRight"
            >
              <Button size="small" type="" title="delete">
                <DeleteOutlined style={{ color: 'red' }} />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onFinish = async (values) => {
    if (!isEdit) {
      setSubmit(true);
      const res = await createData(DB.SUBJECTS, values);
      if (res) {
        form.resetFields();
        setOpen(false);
        message.success('Subject added!');
        fetchNotificationData();
        setSubmit(false);
      }
      return;
    }

    const res = await updateDocumentById(DB.SUBJECTS, subjectId, values);
    if (res) {
      setIsEdit(false);
      setSubjectId('');
      form.resetFields();
      setOpen(false);
      message.success('Subject updated!');
      fetchNotificationData();
    }
  };

  return (
    <>
      {open && (
        <Modal
          open={open}
          title="Add subject"
          okButtonProps={{ size: 'large', loading: submit }}
          cancelButtonProps={{ size: 'large', disabled: submit }}
          okText="Submit"
          onOk={form?.submit}
          onCancel={() => {
            setOpen(false);
            if (isEdit) {
              setDataLoading(true);
            }
          }}
        >
          <LoaderComponent spinning={dataLoading} setHeight={20}>
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true }]}
              >
                <Input placeholder="please enter subject title" size="large" />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="please enter subject description"
                  size="large"
                />
              </Form.Item>
            </Form>
          </LoaderComponent>
        </Modal>
      )}

      <div className="mb-16 d-flex justify-between align-center">
        <h4 className="text-header">Subjects List</h4>
        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setOpen(true);
            setIsEdit(false);
            setDataLoading(false);
            form.resetFields();
          }}
        >
          Add Subject
        </Button>
      </div>
      <div className="page-bg student-list">
        <TableComponent
          columns={columns}
          data={notifications}
          loadingData={loading}
          // eslint-disable-next-line no-unused-vars
          onRow={(record) => ({
            onClick: (event) => {
              // Exclude the action column click
              const isActionColumn = event.target.closest('.ant-btn');
              if (!isActionColumn) {
                // handleRowClick(record);
              }
            },
          })}
        />
      </div>
    </>
  );
}

export default SubjectList;
