import { Form } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DB, ROLES, ROUTES } from '../../common/constants';
import { generateRandomPassword } from '../../common/utils';
import { messageContext } from '../../components/AppContextHolder';
import { createDataWithFile } from '../../firebase/collections/utils';
import TeamForm from './components/TeamForm';

function AddTeam() {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [password /* setPassword */] = useState(generateRandomPassword(12));

  const onFinish = async (values) => {
    setButtonLoading(true);
    setSubmitLoading(true);
    const { examDate, profilePhoto, ...restValues } = values || {};
    const newFormValues = {
      ...restValues,
      role: ROLES.FACULTY.key,
      education: restValues?.education || '',
      experience: restValues?.experience || '',
      email: restValues?.email || '',
      phoneNumber: restValues?.phoneNumber || '',
    };
    const res = await createDataWithFile(
      DB.TEAMS,
      newFormValues,
      fileList?.[0]?.originFileObj,
      false,
    );
    if (res) {
      messageContext.success('Team member added!');
      form.resetFields();
      setFileList([]);
      setSubmitLoading(false);
      setButtonLoading(false);
      navigate(ROUTES.TEAM);
    } else {
      setSubmitLoading(false);
      messageContext.error('Try again. Team member not created');
    }
  };
  const initialValues = {
    password,
  };
  return (
    <>
      <div className="mb-16 d-flex justify-between align-center">
        <h4 className="text-header">Add team</h4>
      </div>
      <TeamForm
        form={form}
        submitLoading={submitLoading}
        onFinish={onFinish}
        title=""
        fileList={fileList}
        setFileList={setFileList}
        buttonLoading={buttonLoading}
        initialValues={initialValues}
      />
    </>
  );
}

export default AddTeam;
