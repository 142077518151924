import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Select, Spin } from 'antd';
import { map } from 'lodash';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import logoImage from '../../assets/images/logo.jpeg';
import { COMPANY, DB, ROLES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { messageContext } from '../../components/AppContextHolder';
import { signInWithEmailPassword } from '../../firebase/collections/auth';
import { getLoggedUserDetails } from '../../firebase/collections/users';
import { getAllDocuments } from '../../firebase/collections/utils';
import './auth.scss';

const { required, email } = formValidatorRules;

const Login = () => {
  const [form] = Form.useForm();
  // const { navigate } = useRouter();
  const { initializeAuth } = useContext(AppContext);
  const [loginLoading, setLoginLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(ROLES.STUDENT.key);
  const isAdmin =
    selectedRole === ROLES.ADMIN.key || selectedRole === ROLES.SUPER_ADMIN.key;

  function successCallback(accessToken, userData, refreshToken, admin = true) {
    initializeAuth(accessToken, userData, refreshToken, admin);
    if (userData) {
      // navigate('/', { replace: true });
      // eslint-disable-next-line no-undef
      window.location.href = '/';
    }
  }

  // const handleGoogleSignIn = async () => {
  //   const data = await signInWithGooglePopup();
  //   if (data?.user?.accessToken) {
  //     const accessToken = data?.user?.accessToken;
  //     if (accessToken) {
  //       successCallback(accessToken, data?.userDetails);
  //     }
  //   }
  // };
  const loginAsAdmin = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
        password: values?.password?.trim(),
      };
      const response = await signInWithEmailPassword(formValues);
      if (response?.user) {
        const { accessToken, uid } = response?.user || {};
        const userData = await getLoggedUserDetails(DB?.USERS, uid);
        if (successCallback) {
          successCallback(accessToken, userData, null, true);
          setLoginLoading(false);
        }
      } else {
        form?.setFieldsValue(values);
        setLoginLoading(false);
      }
    } catch (error) {
      setLoginLoading(false);
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  const phoneLoginValidator = async (db, phoneNumber, password) => {
    const data = await getAllDocuments(db, 'phoneNumber', phoneNumber);
    if (data?.length > 0) {
      const askedData = data?.[0];
      if (askedData?.password !== password) {
        messageContext.error('Password incorrect. please try again');
        setLoginLoading(false);
      } else {
        if (successCallback) {
          successCallback(null, askedData, null, false);
        }
        messageContext.success('Logged in successfully!');
        setLoginLoading(false);
      }
    } else {
      messageContext.error('User not found');
      setLoginLoading(false);
    }
  };

  const idLoginValidator = async (db, studentId, password) => {
    const data = await getAllDocuments(db, 'loginId', studentId);
    if (data?.length > 0) {
      const askedData = data?.[0];
      if (askedData?.password !== password) {
        messageContext.error('Password incorrect. please try again');
        setLoginLoading(false);
      } else {
        if (successCallback) {
          successCallback(null, askedData, null, false);
        }
        messageContext.success('Logged in successfully!');
        setLoginLoading(false);
      }
    } else {
      messageContext.error('User not found');
      setLoginLoading(false);
    }
  };

  const loginAsUser = async (values) => {
    try {
      const phoneNumber = values?.phoneNumber?.trim();
      const studentId = values?.studentId?.trim();
      const password = values?.password?.trim();
      const isStudent = selectedRole === ROLES.STUDENT.key;
      if (isStudent) {
        idLoginValidator(DB.STUDENTS, studentId, password);
      } else {
        phoneLoginValidator(DB.TEAMS, phoneNumber, password);
      }
    } catch (error) {
      setLoginLoading(false);
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  const onFinish = async (values) => {
    setLoginLoading(true);
    if (isAdmin) {
      loginAsAdmin(values);
      return;
    }
    if (selectedRole === ROLES.STUDENT.key) {
      loginAsUser(values);
      return;
    }
    loginAsUser(values);
  };

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={loginLoading} wrapperClassName="full-width">
            <div className="text-center mb-32 login-logo-container">
              <img src={logoImage} alt="logo" />
            </div>
            <Form
              name="Login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
              form={form}
            >
              <Form.Item name="role">
                <Select
                  onChange={setSelectedRole}
                  placeholder="Please select role"
                  defaultValue={selectedRole}
                >
                  {map(ROLES, (role) => (
                    <Select.Option key={role.key}>{role.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {
                // eslint-disable-next-line no-nested-ternary
                isAdmin ? (
                  <Form.Item
                    name="email"
                    rules={[
                      { required, message: 'Please enter email!' },
                      email,
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined />}
                      placeholder="Enter email"
                    />
                  </Form.Item>
                ) : selectedRole === ROLES.FACULTY.key ? (
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      { required, message: 'Please enter phone number!' },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined />}
                      placeholder="Enter phone number"
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="studentId"
                    rules={[
                      { required, message: 'Please enter phone Student id!' },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined />}
                      placeholder="Enter Student id"
                    />
                  </Form.Item>
                )
              }

              <Form.Item
                name="password"
                className="mb-24"
                rules={[{ required, message: 'Please enter password!' }]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter password"
                />
              </Form.Item>
              <Form.Item className=" full-width mb-8">
                <Button type="primary" className="full-width" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <div className="text-center login-copyrights">
          {`${COMPANY.NAME} © ${new Date().getFullYear()}`}
        </div>
      </div>
    </div>
  );
};

export default Login;
