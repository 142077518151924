import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';

const PrivateRoute = () => {
  const { authUser } = useContext(AppContext);

  // if (permissionLoading) {
  //   return <LoaderComponent />;
  // }
  return !authUser ? <Navigate to={ROUTES?.LOGIN} /> : <Outlet />;
};

export default PrivateRoute;
