import React from 'react';
import useUserPermission from '../hooks/usePermission';

const Permission = ({ allowedRoles = [], children }) => {
  const { userRole } = useUserPermission();

  // Check if the user has any of the allowed roles
  const hasPermission = allowedRoles.includes(userRole);

  return hasPermission ? <>{children}</> : null;
};

export default Permission;
